import { Navigate, useRoutes } from 'react-router-dom';
import { useGetRoles, useGetToken } from './hooks/useHandleSessions';
import { TOKEN_PREFIX } from './server/api/http';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import LogoutPage from './pages/LogoutPage';
import DashboardAdminApp from './pages/DashboardAdminApp';
import RegisterPage from './pages/RegisterPage';
import AppointmentDetailsPage from './pages/Appointments/AppointmentUpdatePage';
import DashboardEmployeeApp from './pages/DashboardEmployeeApp';
import ProfilePage from './pages/Profile';
import SectionPage from './pages/Session/SessionPage';
import StaffPage from './pages/Staffs/StaffPage';
import AppointmentsPage from './pages/Appointments/AppointmentsPage';
import StaffDetailsPage from './pages/Staffs/StaffUpdatePage';
import LeavePage from './pages/Leave/LeavePage';
import Enquiry from './pages/Enquiry/EnquiryPage';
import HolidayPage from './pages/Holiday/HolidayPage';
import Review from './Review/Review';

// ----------------------------------------------------------------------

export default function Router() {
  const { token, isSuccess } = useGetToken(TOKEN_PREFIX);
  const { role } = useGetRoles();

  const handleAdminLayout = () => {
    if (isSuccess && token && role === 'admin') {
      return <DashboardLayout />;
    }
    return <Navigate to="/auth/login" />;
  };

  const handleCustomerLayout = () => {
    if (isSuccess && token && role === 'customer') {
      return <SimpleLayout />;
    }
    return <Navigate to="/auth/login" />;
  };

  const adminRoutes = [
    // staff
    {
      path: 'staff',
      element: <StaffPage />,
    },
    { path: 'staff/:name', element: <StaffDetailsPage /> },

    // session
    {
      path: 'session',
      element: <SectionPage />,
    },

    // appointments
    {
      path: 'appointments',
      element: <AppointmentsPage />,
    },
    { path: 'appointments/:name', element: <AppointmentDetailsPage /> },

    // done

    // leave
    {
      path: 'leave',
      element: <LeavePage />,
    },

    // Holiday
    {
      path: 'holiday',
      element: <HolidayPage />,
    },

    // /tuition/batch
    {
      path: 'enquiry',
      element: <Enquiry />,
    },
    {
      path: 'review',
      element: <Review />,
    },
  ];

  const employeeRoutes = [];

  const routesList = [
    {
      path: '/dashboard',
      element: handleAdminLayout(),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: role === 'admin' ? <DashboardAdminApp /> : <DashboardEmployeeApp /> },

        { path: 'profile', element: <ProfilePage /> },

        { path: '404', element: <Page404 isInner /> },
        { path: '*', element: <Navigate to="/dashboard/404" /> },
      ],
    },

    // only logged customer can access
    {
      path: '/service',
      element: handleCustomerLayout(),
      children: [
        { element: <Navigate to="/service/booking" />, index: true },
        { path: 'booking', element: 'Booking Page' },

        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },

    // public
    {
      element: <SimpleLayout />,
      children: [
        { path: '/auth/login', element: <LoginPage />, index: true },

        {
          path: '/auth',
          children: [
            {
              path: 'login',
              element: <LoginPage />,
            },
            {
              path: 'register',
              element: <RegisterPage />,
            },
            {
              path: 'logout',
              element: <LogoutPage />,
            },
          ],
        },

        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },

    // handle notfound routes
    {
      path: '/',
      element: <Navigate to="/auth/login" replace />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const handleRouteByRoles = () => {
    switch (role) {
      case 'admin': {
        const modifiedRoute = routesList?.map((route) =>
          route.path === '/dashboard'
            ? {
                ...route,
                children: [...route.children, ...adminRoutes],
              }
            : route
        );
        return modifiedRoute;
      }
      case 'employee': {
        const modifiedRoute = routesList?.map((route) =>
          route.path === '/dashboard'
            ? {
                ...route,
                children: [...route.children, ...employeeRoutes],
              }
            : route
        );
        return modifiedRoute;
      }
      default:
        return routesList;
    }
  };

  const routes = useRoutes(handleRouteByRoles());

  return routes;
}
