import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-unresolved
import { activeSnack } from 'server/store/common';
import { get } from './http';

export const getDashboardDatas = createAsyncThunk('static/dashboard', async ({ dispatch }) => {
  try {
    const response = await get('/static/dashboard/data');
    return {
      response,
    };
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
