import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { get, post, del, put } from './http';

export const createLeave = createAsyncThunk('leave/create', async ({ state, dispatch, handleClose, reset }) => {
  try {
    const response = await post(`/leave/admin/new`, state);
    if (response) {
      handleClose();
      reset();
      dispatch(activeSnack({ type: 'success', message: 'Create Leave SuccessFully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message || 'something went wrong' }));
    throw error?.response?.data?.message;
  }
});

export const getAllLeave = createAsyncThunk(
  'leave/list',
  async ({ page, limit, search, dispatch, sortDirection, sortBy, sortDate: { from, to }, staffId }) => {
    try {
      const URL = `/leave/admin/all?page=${page && page}&limit=${limit && limit}&search=${search && search}&limit=${
        limit && limit
      }&fromDate=${from && from}&toDate=${to && to}&sortBy=${sortBy && sortBy}&sortDirection=${
        sortDirection && sortDirection
      }&staff=${staffId && staffId}
      `;
      const response = await get(URL);
      if (response) {
        return {
          list: response?.leaves,
          total: response?.total,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteLeave = createAsyncThunk('leave/delete', async ({ leaveId, dispatch, handleCloseDeleteDialog }) => {
  try {
    const response = await del(`/leave/admin/${leaveId}/`);
    if (response) {
      handleCloseDeleteDialog();
      dispatch(activeSnack({ type: 'success', message: 'Leave deleted Successfully' }));
      return response?._id;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getLeaveDetails = createAsyncThunk('leave/single', async ({ leaveId, dispatch }) => {
  try {
    const response = await get(`/leave/admin/${leaveId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateLeaveDetails = createAsyncThunk(
  'leave/update',
  async ({ state, leaveId, dispatch, handleClose }) => {
    try {
      const response = await put(`/leave/admin/${leaveId}`, state);
      if (response) {
        handleClose();
        dispatch(activeSnack({ type: 'success', message: 'Update Leave SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message || 'something went wrong' }));
      throw error?.response?.data?.message;
    }
  }
);
