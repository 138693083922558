import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';

// @mui
import {
  Stack,
  Alert,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
// components
import { colors } from '../../../theme/colors';
import { createLeave, updateLeaveDetails } from '../../../server/api/leave';
import { getAllStaff } from '../../../server/api/staff';
import { clearLeaveError } from '../../../server/store/leave';

CreateLeaveDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenLeaveId: PropTypes.func,
  isUpdate: PropTypes.any,
};

export default function CreateLeaveDialog({ open, setOpen, isUpdate, setOpenLeaveId }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();

  const { loading, error, staffLoading, staffsList } = useSelector((state) => ({
    loading: state.leave.loading,
    error: state.leave.error,
    staffLoading: state.staffs.loading,
    staffsList: state.staffs.staffList,
    // leavesDetails: state.leave.leavesDetails,
  }));

  const [selectedStaffId, setSelectedStaffId] = useState('');
  const [selectedStaff, setSelectedStaff] = useState('');

  const handleClose = () => {
    setOpen(false);
    setOpenLeaveId({});
    dispatch(clearLeaveError());
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      staff: '',
      date: '',
      reason: '',
    },
  });

  useEffect(() => {
    if (isUpdate?._id) {
      setSelectedStaffId(isUpdate?.staff?._id);
      setSelectedStaff(isUpdate?.staff?.name);
    } else {
      setSelectedStaffId(null);
      setSelectedStaff(null);
    }
  }, [dispatch, isUpdate]);

  useEffect(() => {
    if (isUpdate?._id) {
      reset({
        staff: isUpdate?.staff?.name,
        date: moment(isUpdate?.date).format('YYYY-MM-DD'),
        reason: isUpdate?.reason,
      });
    } else {
      reset({
        staff: '',
        date: '',
        reason: '',
      });
    }
  }, [reset, isUpdate?._id, isUpdate?.staff?.name, isUpdate?.date, isUpdate?.reason]);

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: { ...formData, date: new Date(formData?.date), staff: selectedStaffId },
      dispatch,
      handleClose,
      reset,
    };

    if (isUpdate?._id) {
      const updateCredentials = {
        ...credentials,
        leaveId: isUpdate?._id,
      };
      dispatch(updateLeaveDetails(updateCredentials));
    } else {
      dispatch(createLeave(credentials));
    }
  };

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getAllStaff(credentials));
  }, [dispatch]);

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{`${isUpdate?._id ? 'Edit' : 'Send a'} leave request`}</DialogTitle>
        <DialogContent sx={{ minWidth: '500px' }}>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column" spacing={2}>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>

              <Stack spacing={2} direction={'row'}>
                <Stack width={'100%'} spacing={2} direction={'row'}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    loading={staffLoading}
                    options={(staffsList?.list || [])?.map((item) => ({
                      id: item?._id,
                      label: item?.name,
                    }))}
                    sx={{ width: 300 }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedStaffId(newValue?.id);
                        setSelectedStaff(newValue?.label);
                      } else {
                        setSelectedStaffId(null);
                        setSelectedStaff(null);
                      }
                    }}
                    value={selectedStaff}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Staff"
                        {...register('staff', {
                          required: { value: true, message: 'Staff is required' },
                        })}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Stack>
                <TextField
                  name="date"
                  label="Date"
                  {...register('date', {
                    required: { value: true, message: 'Date is required' },
                  })}
                  error={!!errors.date}
                  helperText={errors?.date?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="reason"
                  label="Reason"
                  {...register('reason', {
                    required: { value: true, message: 'reason is required' },
                  })}
                  error={!!errors.reason}
                  helperText={errors?.reason?.message}
                  type="textarea"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: '100%', mb: 2 }}
                />
              </Stack>

              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    padding: '5px 20px',
                  }}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                  autoFocus
                >
                  {isUpdate?._id ? 'Update' : 'Send'}
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
