import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  TextField,
  IconButton,
  MenuItem,
} from '@mui/material';
import moment from 'moment';

// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';

// sections
import ListToolbar from '../../segments/@dashboard/common/ListToolbar';
import Label from '../../components/label/Label';
import ListHead from '../../segments/@dashboard/common/ListHead';
import DeleteConfirmDialog from '../../segments/@dashboard/common/DeleteConfirm';
import CreateEnquiryDialog from '../../segments/@dashboard/enquiry/EnquiryCreateDialog';
import { deleteEnquiry, getAllEnquiry } from '../../server/api/enquiry';
import TextTruncate from '../../components/Typography/TextTruncate';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  // { id: '_id', label: 'Sl no', alignRight: false },
  { id: 'created_on', label: 'Created On', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'message', label: 'Message', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

export default function Enquiry() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('');

  const { loading, enquiryList } = useSelector((state) => ({
    loading: state.enquiry.loading,
    enquiryList: state.enquiry.enquiryList,
  }));

  const [enquiryType, setEnquiryType] = useState({
    tab: 0,
    label: 'courses',
  });

  const [sortDate, setSortDate] = useState({
    from: '',
    to: '',
  });

  const [openEnquiryId, setOpenEnquiryId] = useState({});

  const [openCreateEnquiryDialog, setOpenCreateEnquiryDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [sortDirection, setSortDirection] = useState('asc'); // asc , desc
  const [sortBy, setSortBy] = useState('');

  const [selectedEnquiryIds, setSelectedEnquiryIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const total = enquiryList?.total;

  useEffect(() => {
    const requestData = {
      page: page + 1,
      limit,
      search: searchText,
      sortBy,
      sortDirection,
      dispatch,
      sortDate,
      status,
    };
    dispatch(getAllEnquiry(requestData));
  }, [dispatch, page, limit, searchText, sortBy, sortDirection, sortDate, status]);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = enquiryList?.list?.map((n) => n._id);
      setSelectedEnquiryIds(newSelectedIds);
      return;
    }
    setSelectedEnquiryIds([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setSearchText(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const statusOptions = [
    { label: 'New', value: 'new' },
    { label: 'Opened', value: 'opened' },
    { label: 'Closed', value: 'closed' },
  ];

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * limit - total) : 0;

  const handleDeleteClick = (item) => {
    setOpenEnquiryId(item);
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      enquiryId: openEnquiryId?._id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteEnquiry(credentials));
  };

  const handleEditClick = (item) => {
    if (item) {
      setOpenEnquiryId(item);
    }
    setOpenCreateEnquiryDialog(true);
  };

  const hanldeStatusColors = (status) => (status === 'new' ? 'primary' : status === 'opened' ? 'success' : 'error');

  return (
    <>
      <MetaHelmet title="Enquiry" />
      {openCreateEnquiryDialog && (
        <CreateEnquiryDialog
          setOpen={setOpenCreateEnquiryDialog}
          open={openCreateEnquiryDialog}
          isUpdate={openEnquiryId}
          setOpenEnquiryId={setOpenEnquiryId}
        />
      )}

      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        item={[openEnquiryId.name]}
        title="Enquiry"
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          {'Enquiry'}
        </Typography>
        <Button
          onClick={() => setOpenCreateEnquiryDialog(true)}
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
          type="button"
        >
          New Enquiry
        </Button>
      </Stack>
      <Card sx={{ padding: 3 }}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} display={'flex'}>
          <ListToolbar
            numSelected={selectedEnquiryIds.length}
            filterName={searchText}
            onFilterName={handleFilterByName}
            setType={setEnquiryType}
            type={enquiryType}
            // handleDelete={}
            heading={{ title: 'Enquiry list', subTitle: `Total ${enquiryList?.total} Enquiry` }}
            children={''}
          />
          <Stack direction={'row'} alignItems={'center'} sx={{ marginRight: 5 }}>
            <Stack sx={{ paddingRight: 5 }}>
              <TextField
                sx={{ width: 250 }}
                id="outlined-select-verified"
                // {...register('status', {
                //   required: { value: false, message: 'Status is required' },
                // })}
                select
                fullWidth
                label="Status"
                size="small"
                name="status"
                value={status}
                defaultValue={status || ''}
                onChange={handleStatusChange}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <TextField
              name="date"
              label="From Date"
              sx={{ width: '100%', mr: 2 }}
              size="small"
              type="date"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setSortDate({
                  ...sortDate,
                  from: e.target.value,
                })
              }
            />
            <TextField
              name="date"
              label="To Date"
              sx={{ width: '100%' }}
              size="small"
              type="date"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setSortDate({
                  ...sortDate,
                  to: e.target.value,
                })
              }
            />
          </Stack>
        </Stack>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              {loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <CircularProgress color="warning" />
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {enquiryList?.list <= 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          No Enquiry yet!
                        </Typography>

                        <Typography variant="body2">
                          No results found &nbsp;
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <ListHead
                    order={sortDirection}
                    orderBy={sortBy}
                    headLabel={TABLE_HEAD}
                    rowCount={total}
                    onRequestSort={handleRequestSort}
                    numSelected={selectedEnquiryIds.length}
                    onSelectAllClick={handleSelectAllClick}
                    // isCheckBox
                  />
                  <TableBody>
                    {enquiryList?.list?.map((item, key) => {
                      const { _id, name, message, phone, email, createdAt, status } = item;
                      const selectedEnquiry = selectedEnquiryIds.indexOf(_id) !== -1;
                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedEnquiry}>
                          {/* <TableCell padding="checkbox">
                            <Checkbox checked={selectedEnquiry} onChange={(event) => handleClick(event, _id)} />
                          </TableCell> */}

                          {/* <TableCell align="left">
                            <Typography
                              variant="overline"
                              noWrap
                              sx={{ cursor: 'pointer' }}
                              onClick={() => handleEditClick(item)}
                            >
                              {key + 1}
                            </Typography>
                          </TableCell> */}
                          <TableCell key={key} align="left">
                            {moment(createdAt).format('ll')}
                          </TableCell>

                          <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={() => handleEditClick(item)}>
                            {name}
                          </TableCell>
                          <TableCell align="left">
                            <TextTruncate text={message} width={250} />
                          </TableCell>
                          <TableCell align="left">{phone}</TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left" sx={{ textDecoration: 'none' }}>
                            <Label color={hanldeStatusColors(status)}>{status}</Label>
                          </TableCell>

                          <TableCell align="right">
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                              <IconButton size="large" color="inherit" onClick={() => handleEditClick(item)}>
                                <Iconify icon={'eva:edit-fill'} />
                              </IconButton>
                              <IconButton size="large" color="error" onClick={() => handleDeleteClick(item)}>
                                <Iconify icon={'eva:trash-2-outline'} />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        {total > 0 && (
          <TablePagination
            rowsPerPageOptions={[1, 5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Card>
    </>
  );
}
