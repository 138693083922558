import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  CircularProgress,
  Box,
  Button,
  Link,
} from '@mui/material';
// components
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import { MetaHelmet } from '../../../layouts/Helmet/Helmet';
// sections
import ListHead from '../common/ListHead';
import { getAllAppointment } from '../../../server/api/appointment';
import Iconify from '../../../components/iconify/Iconify';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Sl no', alignRight: false },
  { id: 'username', label: 'Name', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: '', label: 'Action', alignRight: false },
];

export default function DashboardAppointmentsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, appointmentsList } = useSelector((state) => ({
    loading: state.appointment.loading,
    appointmentsList: state.appointment.appointmentsList,
  }));

  const [sortDate] = useState({
    from: '',
    to: '',
  });

  const [sortDirection, setSortDirection] = useState('asc'); // asc , desc
  const [sortBy, setSortBy] = useState('');

  const [selectedAppointmentIds, setSelectedAppointmentIds] = useState([]);
  const [limit] = useState(5);
  const total = appointmentsList?.total;

  useEffect(() => {
    const credentials = {
      page: '',
      limit,
      search: '',
      sortBy,
      sortDirection,
      dispatch,
      sortDate,
    };
    dispatch(getAllAppointment(credentials));
  }, [dispatch, limit, sortBy, sortDirection, sortDate]);

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleEdit = (id) => {
    if (id && id !== null) {
      navigate(`/dashboard/appointments/${id}`, {
        state: id,
      });
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = appointmentsList?.list?.map((n) => n._id);
      setSelectedAppointmentIds(newSelectedIds);
      return;
    }
    setSelectedAppointmentIds([]);
  };

  return (
    <>
      <MetaHelmet title="Appointments" />

      <Card sx={{ padding: 2, minHeight: 505 }}>
        <Typography component={''} sx={{ padding: 2, fontSize: ' 1.125rem;', fontWeight: 700 }} variant="subtitle2">
          Appointments
        </Typography>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              {loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <CircularProgress color="warning" />
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {appointmentsList?.list <= 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          No appointments yet!
                        </Typography>

                        <Typography variant="body2">
                          No results found &nbsp;
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <ListHead
                    order={sortDirection}
                    orderBy={sortBy}
                    headLabel={TABLE_HEAD}
                    rowCount={total}
                    onRequestSort={handleRequestSort}
                    numSelected={selectedAppointmentIds.length}
                    onSelectAllClick={handleSelectAllClick}
                    // isCheckBox
                  />
                  <TableBody>
                    {appointmentsList?.list?.map((item, key) => {
                      const { _id, name, date } = item;
                      console.log(_id);
                      const selectedAppointment = selectedAppointmentIds.indexOf(_id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={`${_id}-appointment`}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedAppointment}
                        >
                          {/* <TableCell padding="checkbox">
                              <Checkbox checked={selectedAppointment} onChange={(event) => handleClick(event, _id)} />
                            </TableCell> */}

                          <TableCell align="left">
                            <Typography variant="overline" noWrap sx={{ cursor: 'pointer' }}>
                              {key + 1}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2} sx={{ cursor: 'pointer' }}>
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          {/* <TableCell align="left">{phone}</TableCell> */}

                          <TableCell align="left">
                            <Typography variant="caption" noWrap>
                              {moment(date).format('ll')}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <Typography onClick={() => handleEdit(_id)} sx={{ textDecoration: 'none' }}>
                              <Label sx={{ cursor: 'pointer' }}>View More</Label>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <Box sx={{ px: 0, pt: 2, textAlign: 'right' }}>
          <Link href="/dashboard/appointments" sx={{ textDecoration: 'none' }}>
            <Button size="small" color="inherit" endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}>
              View all
            </Button>
          </Link>
        </Box>
      </Card>
    </>
  );
}
