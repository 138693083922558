import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { del, get, post, put } from './http';

export const createReview = createAsyncThunk(
  'reviews/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/review/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'review created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const updateReviewDetails = createAsyncThunk(
  'reviews/update',
  async ({ state, reviewId, dispatch, handleClose }) => {
    try {
      const URL = `/review/admin/${reviewId}`;

      const response = await put(URL, state);
      if (response) {
        handleClose();
        dispatch(activeSnack({ type: 'success', message: 'Review updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllReview = createAsyncThunk('reviews/list', async ({ page, search, dispatch, limit }) => {
  try {
    // const URL_DROPDOWN = `/review/admin/all`;

    const response = await get(
      `/review/admin/all?page=${page && page}&search=${search && search}&limit=${limit && limit}`
    );
    if (response) {
      return {
        list: response?.reviews,
        total: response?.total,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getReviewDetails = createAsyncThunk('reviews/single', async ({ reviewId, dispatch }) => {
  try {
    const response = await get(`/review/admin/${reviewId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const deleteReview = createAsyncThunk(
  'reviews/delete',
  async ({ reviewId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/review/admin/${reviewId}`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Review deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
