import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import { Grid, Link, Stack } from '@mui/material';

// sections
import AppWidgetSummary from './AppWidgetSummary';
import { getDashboardDatas } from '../../../server/api/dashboard';
import DashboardAppointmentsPage from './DashboardAppointmentList';
import DashboardEnquiry from './DashboardEnquiryList';

function DashboardContent() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({
    data: state.dashboard.dashboardData.response,
  }));

  useEffect(() => {
    const data = {
      dispatch,
    };
    dispatch(getDashboardDatas(data));
  }, [dispatch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} className="cardPadding">
        <Link sx={{ textDecoration: 'none' }} href={`/dashboard/appointments`}>
          <AppWidgetSummary
            title="Appointments"
            todayCount={data?.TodayAppointmentCount || 0}
            total={data?.TotalAppointmentCount || 0}
            icon={'ic:baseline-book'}
          />
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Stack className="card2">
          <Link sx={{ textDecoration: 'none' }} href={`/dashboard/staff`}>
            <AppWidgetSummary
              title="Staffs"
              total={data?.TotalStaffCount || 0}
              todayCount={data?.TodayStaffCount || 0}
              color="warning"
              icon={'icon-park-outline:file-staff'}
            />
          </Link>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Stack className="card3">
          <Link sx={{ textDecoration: 'none' }} href={`/dashboard/leave`}>
            <AppWidgetSummary
              title="Attendance"
              todayCount={data?.LeaveCount || 0}
              total={data?.TotalStaffCount - data?.LeaveCount || 0}
              color="info"
              icon={'pepicons-pop:leave-circle'}
            />
          </Link>
        </Stack>
      </Grid>

      <Grid item xs={12} md={6} lg={8}>
        <DashboardAppointmentsPage />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <DashboardEnquiry />
      </Grid>
    </Grid>
  );
}

export default DashboardContent;
