import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { del, get, post, put } from './http';

export const createAppointment = createAsyncThunk('appointment/create', async ({ state, dispatch, handleClose }) => {
  try {
    const response = await post(`/appointment/admin/new`, state);
    if (response) {
      handleClose();
      dispatch(activeSnack({ type: 'success', message: 'Create Appointment SuccessFully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message || 'something went wrong' }));
    throw error?.response?.data?.message;
  }
});

export const getAllAppointment = createAsyncThunk(
  'appointment/list',
  async ({ page, limit, search, dispatch, sortDate: { from, to }, status }) => {
    try {
      // const URL = `/appointment/admin/all?page=${page && page}&limit=${limit && limit}&search=${
      //   search && search
      // }&fromDate=${from || ''}&toDate=${to || ''}`;
      const URL = `/appointment/admin/all?page=${page || ''}&limit=${limit || ''}&search=${search || ''}&fromDate=${
        from || ''
      }&toDate=${to || ''}&status=${status || ''}`;
      // const URL_DROPDOWN = '/appointment/admin/all';
      // &limit=${limit && limit}&sortBy=${
      //   sortBy && sortBy
      // }&sortDirection=${sortDirection && sortDirection}
      const response = await get(URL);
      if (response) {
        return {
          list: response?.appointments,
          total: response?.total,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteAppointment = createAsyncThunk(
  'appointment/delete',
  async ({ appointmentId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/appointment/admin/${appointmentId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Appointment deleted Successfully' }));
        return appointmentId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAppointmentDetails = createAsyncThunk('appointment/single', async ({ appointmentId, dispatch }) => {
  try {
    const response = await get(`/appointment/admin/${appointmentId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateAppointmentDetails = createAsyncThunk(
  'appointment/update',
  async ({ state, appointmentId, dispatch }) => {
    try {
      const response = await put(`/appointment/admin/${appointmentId}`, state);
      if (response) {
        // navigate('/dashboard/appointments');
        dispatch(activeSnack({ type: 'success', message: 'Update Appointment SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message || 'something went wrong' }));
      throw error?.response?.data?.message;
    }
  }
);

//
export const getAppointmentSlots = createAsyncThunk('appointment/slot', async ({ dispatch }) => {
  try {
    const URL = `/appointment/slots`;
    const response = await get(URL);
    if (response) {
      return {
        list: response,
        total: response?.length,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
