import {
  Button,
  Card,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityIcon from '@mui/icons-material/Visibility';

import Iconify from '../components/iconify/Iconify';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { MetaHelmet } from '../layouts/Helmet/Helmet';
import ListHead from '../segments/@dashboard/common/ListHead';
import ListToolbar from '../segments/@dashboard/common/ListToolbar';
import { deleteReview, getAllReview } from '../server/api/Review';
import CreateReviewDialog from '../segments/@dashboard/review/review';
import DeleteConfirmDialog from '../segments/@dashboard/common/DeleteConfirm';
import TextTruncate from '../components/Typography/TextTruncate';

export default function Review() {
  const dispatch = useDispatch();
  const TABLE_HEAD = [
    // { id: '_id', label: 'Sl no', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'message', label: 'Message', alignRight: false },
    { id: 'phone', label: 'Phone', alignRight: false },
    { id: 'workQulaityRating', label: 'Work Quality Rating', alignRight: false },
    { id: 'customerRelationRating', label: 'Customer Relation Rating', alignRight: false },
    { id: 'experienceRating', label: 'Experience Rating', alignRight: false },
    { id: 'averageRating', label: 'Average Rating', alignRight: false },
    { id: '', label: 'Action', alignRight: true },
  ];

  const { loading, reviewList } = useSelector((state) => ({
    loading: state.reviews.loading,
    reviewList: state.reviews.reviewList,
  }));

  const [enquiryType, setEnquiryType] = useState({
    tab: 0,
    label: 'courses',
  });
  const [selectedEnquiryIds, setSelectedEnquiryIds] = useState([]);
  const [openCreateReviewDialog, setOpenCreateReviewDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [sortDirection, setSortDirection] = useState('asc'); // asc , desc
  const [sortBy, setSortBy] = useState('');

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const total = reviewList?.total;

  const [openReviewId, setOpenReviewId] = useState({});
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * limit - total) : 0;

  const handleFilterByName = (event) => {
    setPage(0);
    setSearchText(event.target.value);
  };

  const handleEditClick = (item) => {
    if (item) {
      setOpenReviewId(item);
    }
    setOpenCreateReviewDialog(true);
  };

  const handleDeleteClick = (item) => {
    setOpenReviewId(item);
    setOpenDeleteConfirmDialog(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = reviewList?.list?.map((n) => n._id);
      setSelectedEnquiryIds(newSelectedIds);
      return;
    }
    setSelectedEnquiryIds([]);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  useEffect(() => {
    const data = {
      page: page + 1,
      limit,
      search: searchText,
      dispatch,
    };
    dispatch(getAllReview(data));
  }, [dispatch, page, searchText, limit]);

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      reviewId: openReviewId?._id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteReview(credentials));
  };

  return (
    <>
      <MetaHelmet title="Review" />
      <CreateReviewDialog
        setOpen={setOpenCreateReviewDialog}
        open={openCreateReviewDialog}
        isUpdate={openReviewId}
        setOpenReviewId={setOpenReviewId}
      />

      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        item={[openReviewId.name]}
        title="Enquiry"
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          {'Review'}
        </Typography>
        <Button
          onClick={() => setOpenCreateReviewDialog(true)}
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
          type="button"
        >
          New Review
        </Button>
      </Stack>

      <Card sx={{ padding: 3 }}>
        <Stack display={'flex'} alignItems={'start'} justifyContent={'start'}>
          <ListToolbar
            numSelected={selectedEnquiryIds.length}
            filterName={searchText}
            onFilterName={handleFilterByName}
            setType={setEnquiryType}
            type={enquiryType}
            // handleDelete={}
            heading={{ title: 'Review list', subTitle: `Total ${reviewList?.total} Reviews` }}
          />
        </Stack>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              {loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <CircularProgress color="warning" />
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {reviewList?.list <= 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          No Enquiry yet!
                        </Typography>

                        <Typography variant="body2">
                          No results found &nbsp;
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <ListHead
                    order={sortDirection}
                    orderBy={sortBy}
                    headLabel={TABLE_HEAD}
                    rowCount={total}
                    onRequestSort={handleRequestSort}
                    numSelected={selectedEnquiryIds.length}
                    onSelectAllClick={handleSelectAllClick}
                    // isCheckBox
                  />
                  <TableBody>
                    {reviewList?.list?.map((item, key) => {
                      const {
                        _id,
                        name,
                        message,
                        phone,
                        workQualityRating,
                        customerRelationRating,
                        experienceRating,
                        averageRating,
                      } = item;
                      const selectedEnquiry = selectedEnquiryIds.indexOf(_id) !== -1;
                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedEnquiry}>
                          {/* <TableCell padding="checkbox">
                            <Checkbox checked={selectedEnquiry} onChange={(event) => handleClick(event, _id)} />
                          </TableCell> */}

                          {/* <TableCell align="left">
                            <Typography
                              variant="overline"
                              noWrap
                              sx={{ cursor: 'pointer' }}
                              onClick={() => handleEditClick(item)}
                            >
                              {key + 1}
                            </Typography>
                          </TableCell> */}
                          <TableCell
                            key={key}
                            sx={{ cursor: 'pointer' }}
                            align="left"
                            onClick={() => handleEditClick(item)}
                          >
                            {name}
                          </TableCell>
                          <TableCell align="left">{<TextTruncate text={message} width={250} />}</TableCell>
                          <TableCell align="left">{phone}</TableCell>
                          <TableCell align="center">{workQualityRating}</TableCell>
                          <TableCell align="center">{customerRelationRating}</TableCell>
                          <TableCell align="center">{experienceRating}</TableCell>
                          <TableCell align="center">{averageRating?.toFixed(1) || 0}</TableCell>

                          <TableCell align="right">
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                              <IconButton size="large" color="inherit" onClick={() => handleEditClick(item)}>
                                <VisibilityIcon titleAccess="View Deatils" />
                              </IconButton>
                              <IconButton size="large" color="error" onClick={() => handleDeleteClick(item)}>
                                <Iconify icon={'eva:trash-2-outline'} />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        {total > 0 && (
          <TablePagination
            rowsPerPageOptions={[1, 5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Card>
    </>
  );
}
