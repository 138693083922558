import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { del, get, post, put } from './http';

export const createEnquiry = createAsyncThunk('enquiry/create', async ({ state, dispatch, handleClose }) => {
  try {
    const response = await post(`/enquiry/admin/new`, state);
    if (response) {
      handleClose();
      dispatch(activeSnack({ type: 'success', message: 'Create Enquiry SuccessFully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message || 'something went wrong' }));
    throw error?.response?.data?.message;
  }
});

export const getAllEnquiry = createAsyncThunk(
  'enquiry/list',
  async ({ page, limit, search, dispatch, sortDate: { from, to }, status, isDropDown }) => {
    try {
      let URL = '';

      if (isDropDown) {
        URL = `/enquiry/admin/all?page=${page && page}&limit=${limit && limit}`;
      } else {
        URL = `/enquiry/admin/all?page=${page && page}&search=${search && search}&limit=${limit && limit}&fromDate=${
          from && from
        }&toDate=${to && to}&status=${status || ''}`;
      }

      // &sortBy=${sortBy && sortBy}&sortDirection=${sortDirection && sortDirection}`;

      const response = await get(URL);
      if (response) {
        return {
          list: response?.enquiries,
          total: response?.total,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteEnquiry = createAsyncThunk(
  'enquiry/delete',
  async ({ enquiryId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/enquiry/admin/${enquiryId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Enquiry deleted Successfully' }));
        return enquiryId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getEnquiryDetails = createAsyncThunk('enquiry/single', async ({ enquiryId, dispatch }) => {
  try {
    const response = await get(`/enquiry/admin/${enquiryId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateEnquiryDetails = createAsyncThunk(
  'enquiry/update',
  async ({ state, enquiryId, dispatch, handleClose, navigate }) => {
    try {
      const response = await put(`/enquiry/admin/${enquiryId}/`, state);
      if (response) {
        if (navigate) {
          navigate();
        }
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Update Enquiry SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message || 'something went wrong' }));
      throw error?.response?.data?.message;
    }
  }
);
