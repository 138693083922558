import { configureStore } from '@reduxjs/toolkit';
import commonReducer from './common';
import authReducer from './auth';
import staffReducer from './staff';
import holidayReducer from './holiday';
import leaveReducer from './leave';
import appointmentsReducer from './appointments';
import enquiryReducer from './enquiry';
import sessionsReducer from './session';
import appReducer from './app';
import dashboardReducer from './dashboard';
import ReviewReducer from './review';

export default configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    staffs: staffReducer,
    appointment: appointmentsReducer,
    enquiry: enquiryReducer,
    sessions: sessionsReducer,
    leave: leaveReducer,
    holiday: holidayReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    reviews: ReviewReducer,
  },
});
