import { createSlice } from '@reduxjs/toolkit';
import { createSession, getAllSession, getSessionDetails, deleteSession, updateSessionDetails } from '../api/sessions';
import { covertToJSON } from '../../utils/stateToJson';

export const sessionSlice = createSlice({
  name: 'sessions',
  initialState: {
    loading: false,
    error: {},
    sessionsList: {},
    sessionsDropList: [],
    sessionsDetails: {},
  },
  reducers: {
    clearSessionError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create sessions details
    [createSession.pending]: (state) => {
      state.loading = true;
    },
    [createSession.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.sessionsList;
      const modifiedSessionsList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
      };
      state.loading = false;
      state.sessionsDetails = action.payload;
      state.sessionsList = modifiedSessionsList;
      state.error = {};
    },
    [createSession.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all sessions
    [getAllSession.pending]: (state) => {
      state.loading = true;
    },
    [getAllSession.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.sessionsList = action.payload;
    },
    [getAllSession.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single sessions details
    [getSessionDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSessionDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.sessionsDetails = action.payload;
    },
    [getSessionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update sessions details
    [updateSessionDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateSessionDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.sessionsList;
      const modifiedSessionList = {
        ...jsonState,
        list: jsonState.list?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
        // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
      };
      state.loading = false;
      state.sessionsList = modifiedSessionList;
      state.sessionsDetails = action.payload;
      state.error = {};
    },
    [updateSessionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteSession.pending]: (state) => {
      state.loading = true;
    },
    [deleteSession.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.sessionsList;
      const modifiedSessionList = {
        ...jsonState,
        list: jsonState.list?.filter((session) => session._id !== action.payload),
      };
      state.loading = false;
      state.sessionsList = modifiedSessionList;
      state.error = {};
    },
    [deleteSession.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearSessionError } = sessionSlice.actions;

export default sessionSlice.reducer;
