import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// @mui
import { Card, Stack, Button, Container, Avatar, Chip, Box, Alert, TextField } from '@mui/material';
import { EmailRounded, KeyboardBackspace, PhoneRounded } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';
import { getStaffDetails, updateStaffDetails } from '../../server/api/staff';
// components
import Iconify from '../../components/iconify';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
import { colors } from '../../theme/colors';

export default function StaffDetailsPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { loading, error, staffDetails } = useSelector((state) => ({
    loading: state.staffs.loading,
    staffDetails: state.staffs.staffDetails,
    error: state.staffs.error,
  }));

  const staffId = location.state;

  const [isSaved, setIsSaved] = useState(false);
  const [editAvatar, setEditAvatar] = useState(false);

  useEffect(() => {
    const credentials = {
      staffId,
      dispatch,
    };
    dispatch(getStaffDetails(credentials));
  }, [dispatch, staffId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      designation: '',
      phone: '',
    },
  });

  useEffect(() => {
    reset({
      name: staffDetails?.name,
      email: staffDetails?.email,
      designation: staffDetails?.designation,
      phone: staffDetails?.phone,
    });
  }, [reset, staffDetails]);

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: { ...staffDetails, ...formData },
      dispatch,
      staffId: staffDetails?._id,
      navigate: isSaved === true ? navigate('/dashboard/staff') : '',
    };
    dispatch(updateStaffDetails(credentials));
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <MetaHelmet title="Users" />
      <Container>
        <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Stack direction="row" alignItems="center">
              <KeyboardBackspace onClick={() => navigate(-1)} sx={{ cursor: 'pointer', mr: 2, mb: 1 }} />
              <Typography variant="h4" gutterBottom>
                Staff Details
              </Typography>
            </Stack>
            <Button
              variant="contained"
              color="inherit"
              sx={{ backgroundColor: colors.green, color: 'white' }}
              startIcon={<Iconify icon="eva:save-fill" />}
              type="submit"
              onClick={() => setIsSaved(true)}
            >
              Save
            </Button>
          </Stack>
          <Stack
            sx={{ width: '100%', mb: 2 }}
            direction={'row-reverse'}
            alignSelf={'center'}
            justifyContent={'space-between'}
            className="single-view-taps"
          >
            <Box>
              <Tabs value={value} onChange={handleChange} className="single-view-tab" aria-label="basic tabs example">
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Update" {...a11yProps(1)} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Card sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <Stack>
                  <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
                    <Stack direction={'row'}>
                      <Tooltip title="Change Profile">
                        <Avatar
                          variant="rounded"
                          onClick={() => setEditAvatar(!editAvatar)}
                          sx={{ cursor: 'pointer', mr: 2 }}
                          src={''}
                        />
                      </Tooltip>
                      <Stack spacing={0.5} sx={{ mt: '-5px' }}>
                        <Typography fontWeight={700}>{staffDetails?.name}</Typography>
                        {staffDetails?.email && (
                          <Typography variant="body2" color="text.secondary">
                            <EmailRounded sx={{ color: grey[500], mb: '-3px', fontSize: '16px' }} />{' '}
                            {staffDetails?.email}
                          </Typography>
                        )}
                        {staffDetails?.phone && (
                          <Typography variant="body2" color="text.secondary">
                            <PhoneRounded sx={{ color: grey[500], mb: '-3px', fontSize: '16px' }} />{' '}
                            {staffDetails?.phone}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                    <Chip sx={{ bgcolor: colors.light }} label={staffDetails?.designation} />
                  </Box>
                  <Stack direction={'row'} padding={2} width={'100%'} justifyContent={'end'} alignItems={'center'}>
                    <Typography variant="caption" mr={1} color={'GrayText'} gutterBottom>
                      staff registered from
                    </Typography>
                    <Typography variant="caption" color={'GrayText'} gutterBottom>
                      {moment(staffDetails?.createdAt).format('ll')}
                    </Typography>
                  </Stack>
                </Stack>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Card sx={{ width: '100%', p: 3 }}>
                <Typography variant="p" gutterBottom>
                  Edit Details
                </Typography>
                <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                  {error.message && <Alert severity="error">{error?.message}</Alert>}
                </Stack>
                <Stack spacing={2} direction={'row'}>
                  <TextField
                    name="name"
                    required
                    label="Name"
                    {...register('name', {
                      required: { value: true, message: 'name is required' },
                    })}
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    sx={{ width: '100%', mb: 2 }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    required
                    name="designation"
                    label="Designation"
                    {...register('designation', {
                      required: { value: false, message: 'full name is required' },
                    })}
                    error={!!errors.designation}
                    helperText={errors?.designation?.message}
                    sx={{ width: '100%', mb: 2 }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                </Stack>
                <Stack spacing={2} direction={'row'}>
                  <TextField
                    required
                    name="phone"
                    label="Phone"
                    {...register('phone', {
                      required: { value: false, message: 'Phone is required' },
                    })}
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    sx={{ width: '100%', mb: 2 }}
                    size="small"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    required
                    name="email"
                    label="Email"
                    {...register('email', {
                      required: {
                        value: false,
                        message: 'email is required',
                      },
                    })}
                    sx={{ width: '100%', mb: 2 }}
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    type="email"
                  />
                </Stack>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                  <LoadingButton
                    sx={{
                      backgroundColor: colors.green,
                      color: 'white',
                      mt: 2,
                      px: 4,
                    }}
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="info"
                    loading={loading}
                  >
                    Update
                  </LoadingButton>
                </Stack>
              </Card>
            </TabPanel>
          </Stack>
        </form>
      </Container>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
