import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useEffect } from 'react';

// @mui
import { Stack, Alert, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
// components
import { colors } from '../../../theme/colors';
import { createHoliday, getHolidayDetails, updateHolidayDetails } from '../../../server/api/holiday';
import { clearHolidayError } from '../../../server/store/holiday';

CreateHolidayDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenHolidayId: PropTypes.func,
  isUpdate: PropTypes.any,
};

export default function CreateHolidayDialog({ open, setOpen, isUpdate, setOpenHolidayId }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();

  const { loading, error, holidaysDetails } = useSelector((state) => ({
    loading: state.holiday.loading,
    error: state.holiday.error,
    holidaysDetails: state.holiday.holidaysDetails,
  }));

  const handleClose = () => {
    setOpen(false);
    setOpenHolidayId({});
    dispatch(clearHolidayError());
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      title: '',
      date: '',
      description: '',
    },
  });

  useEffect(() => {
    if (isUpdate?._id) {
      const credentials = {
        dispatch,
        holidayId: isUpdate?._id,
      };
      dispatch(getHolidayDetails(credentials));
    }
  }, [dispatch, isUpdate?._id]);

  useEffect(() => {
    if (isUpdate?._id) {
      reset({
        title: holidaysDetails?.title,
        date: moment(holidaysDetails?.date).format('YYYY-MM-DD'),
        description: holidaysDetails?.description,
      });
    } else {
      reset({
        title: '',
        date: '',
        description: '',
      });
    }
  }, [reset, holidaysDetails, isUpdate?._id]);

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: { ...formData, date: new Date(formData?.date) },
      dispatch,
      handleClose,
    };

    if (isUpdate?._id) {
      const updateCredentials = {
        ...credentials,
        holidayId: isUpdate?._id,
      };
      dispatch(updateHolidayDetails(updateCredentials));
    } else {
      dispatch(createHoliday(credentials));
    }
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{`${
          isUpdate?._id ? 'Edit Holiday Details' : 'Create Holiday'
        }`}</DialogTitle>
        <DialogContent sx={{ minWidth: '500px' }}>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column" spacing={2}>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  required
                  name="title"
                  label="Title"
                  {...register('title', {
                    required: { value: true, message: 'title is required' },
                  })}
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  required
                  name="date"
                  label="Date"
                  {...register('date', {
                    required: { value: true, message: 'Date is required' },
                  })}
                  error={!!errors.date}
                  helperText={errors?.date?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="description"
                  label="Description"
                  {...register('description', {
                    required: { value: false, message: 'description is required' },
                  })}
                  error={!!errors.description}
                  helperText={errors?.description?.message}
                  type="textarea"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: '100%', mb: 2 }}
                />
              </Stack>

              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    padding: '5px 20px',
                  }}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                  autoFocus
                >
                  {isUpdate?._id ? 'Update' : 'Save'}
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
