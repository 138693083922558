import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import { Stack, Typography, Paper, CircularProgress } from '@mui/material';
// components
import { MetaHelmet } from '../../layouts/Helmet/Helmet';

// session
import { getAllSession } from '../../server/api/sessions';
import SessionTable from './SessionTable';

// ----------------------------------------------------------------------

export default function SessionPage() {
  const dispatch = useDispatch();

  const { loading, sessionsList } = useSelector((state) => ({
    loading: state.sessions.loading,
    sessionsList: state.sessions.sessionsList,
  }));

  useEffect(() => {
    const requestData = {
      dispatch,
      page: '',
      limit: '',
      search: '',
    };
    dispatch(getAllSession(requestData));
  }, [dispatch]);

  const items = sessionsList?.list;

  return (
    <>
      <MetaHelmet title="Sessions" />
      <Stack direction="row" alignItems="batch" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Sessions
        </Typography>
      </Stack>
      <SessionTable items={items} />

      {loading && (
        <Paper
          sx={{
            textAlign: 'center',
            width: '100%',
            backgroundColor: 'transparent',
            py: 3,
          }}
        >
          <CircularProgress color="success" />
        </Paper>
      )}
    </>
  );
}
