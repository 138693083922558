import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { del, get, post, put } from './http';

export const createStaff = createAsyncThunk('staffs/create', async ({ state, dispatch, handleClose, handleClear }) => {
  try {
    const URL = `/staff/admin/new`;
    const response = await post(URL, state);
    if (response) {
      handleClose();
      handleClear();
      dispatch(activeSnack({ type: 'success', message: 'staff created successfully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getAllStaff = createAsyncThunk(
  'staffs/list',
  async ({ page, search, dispatch, limit, sortBy, sortDirection }) => {
    try {
      const URL = `/staff/admin/all?page=${page && page}&search=${search && search}&limit=${limit && limit}&sortBy=${
        sortBy && sortBy
      }&sortDirection=${sortDirection && sortDirection}`;

      const URL_DROPDOWN = `/staff/admin/all`;

      const response = await get(page ? URL : URL_DROPDOWN);
      if (response) {
        return {
          list: response?.staffs,
          total: response?.total,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getStaffDetails = createAsyncThunk('staffs/single', async ({ staffId, dispatch }) => {
  try {
    const response = await get(`/staff/admin/${staffId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateStaffDetails = createAsyncThunk('staffs/update', async ({ state, staffId, dispatch, navigate }) => {
  try {
    const URL = `/staff/admin/${staffId}`;

    const response = await put(URL, state);
    if (response) {
      if (navigate) {
        navigate();
      }
      dispatch(activeSnack({ type: 'success', message: 'Staff updated Successfully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const deleteStaff = createAsyncThunk('staffs/delete', async ({ staffId, dispatch, handleCloseDeleteDialog }) => {
  try {
    const response = await del(`/staff/admin/${staffId}/`);
    if (response) {
      handleCloseDeleteDialog();
      dispatch(activeSnack({ type: 'success', message: 'Staff deleted Successfully' }));
      return response?._id;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
