import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// @mui
import { Container, Typography } from '@mui/material';

// components
import { MetaHelmet } from '../layouts/Helmet/Helmet';
import DashboardContent from '../segments/@dashboard/app/DashboardContent';

// action

// sections

// ----------------------------------------------------------------------

export default function DashboardAdminApp() {
  // const dispatch = useDispatch();

  const { loading } = useSelector((state) => ({
    loading: state.common.loading,
  }));

  const [show, setShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, []);

  return (
    <>
      <MetaHelmet title={'Dashboard'} />

      <Container maxWidth="xl">
        {show && (
          <Typography variant="h4" sx={{ mb: 5 }}>
            Hi, Welcome back ....
          </Typography>
        )}
        {!loading ? <DashboardContent /> : 'dashboard'}
      </Container>
    </>
  );
}
