import GroupIcon from '@mui/icons-material/Group';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import DnsIcon from '@mui/icons-material/Dns';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import ReviewsIcon from '@mui/icons-material/Reviews';
// component
import SvgColor from '../../../components/svg-color';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export const adminNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'Users',
  //   path: '/dashboard/accounts',
  //   icon: <AdminPanelSettingsIcon />,
  // },
  {
    title: 'Staff',
    path: '/dashboard/staff',
    icon: <GroupIcon />,
  },
  {
    title: 'Session',
    path: '/dashboard/session',
    icon: <DnsIcon />,
  },

  {
    title: 'Appointments',
    path: '/dashboard/appointments',
    icon: <WorkspacesIcon />,
  },
  {
    title: 'Leave',
    path: '/dashboard/leave',
    icon: <NoAccountsIcon />,
  },
  {
    title: 'Holiday',
    path: '/dashboard/holiday',
    icon: <FreeBreakfastIcon />,
  },
  {
    title: 'Enquiry',
    path: '/dashboard/enquiry',
    icon: <MarkChatUnreadIcon />,
  },
  {
    title: 'Review',
    path: '/dashboard/review',
    icon: <ReviewsIcon />,
  },
  // {
  //   title: 'Course / Batch',
  //   path: '/dashboard/course',
  //   icon: <WorkspacesIcon />,
  // },
  // {
  //   title: 'Subject / Chapter',
  //   path: '/dashboard/subject',
  //   icon: <LibraryBooksIcon />,
  // },
];

export const employeeNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Appointments',
    path: '/dashboard/appointments',
    icon: icon('ic_cart'),
  },
];
