import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import { LoadingButton } from '@mui/lab';
// @mui
import {
  Stack,
  Alert,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  MenuItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// components
import { colors } from '../../../theme/colors';
import { createEnquiry, updateEnquiryDetails } from '../../../server/api/enquiry';
import { clearEnquiryError } from '../../../server/store/enquiry';

CreateEnquiryDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenEnquiryId: PropTypes.func,
  isUpdate: PropTypes.object,
};

export default function CreateEnquiryDialog({ open, setOpen, isUpdate, setOpenEnquiryId }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
    setOpenEnquiryId({});
    dispatch(clearEnquiryError());
  };
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => ({
    loading: state.enquiry.loading,
    error: state.enquiry.error,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      message: '',
    },
  });

  // const [status, setStatus] = useState('Search status');
  // const [statusValue, setStatusValue] = useState('');

  // function handlerFinalValueStatus(event) {
  //   setStatus(event?.label);
  //   setStatusValue(event?.value);
  // }

  // useEffect(() => {
  //   if (isUpdate?.status) {
  //     setStatus(isUpdate?.status);
  //     setStatusValue(isUpdate?.status);
  //   }
  // }, [isUpdate]);

  // console.log(status);

  const [status, setStatus] = useState('new');
  const statusOptions = [
    { label: 'New', value: 'new' },
    { label: 'Opened', value: 'opened' },
    { label: 'Closed', value: 'closed' },
  ];

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    if (isUpdate?._id) {
      setStatus(isUpdate?.status);
    }
  }, [isUpdate]);

  useEffect(() => {
    if (isUpdate?._id) {
      reset({
        name: isUpdate?.name,
        phone: isUpdate?.phone,
        email: isUpdate?.email,
        message: isUpdate?.message,
        status: isUpdate?.status,
      });
    } else {
      reset({
        name: '',
        phone: '',
        email: '',
        message: '',
        status: '',
      });
    }
  }, [reset, isUpdate]);

  console.log(isUpdate);

  const onSubmit = async (formData, e) => {
    console.log(formData);
    e?.preventDefault();
    const credentials = {
      state: formData,
      dispatch,
      handleClose,
    };

    if (isUpdate?._id) {
      const updateCredentials = {
        ...credentials,
        enquiryId: isUpdate?._id,
      };

      dispatch(updateEnquiryDetails(updateCredentials));
    } else {
      delete credentials.state.status;
      dispatch(createEnquiry(credentials));
    }
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{isUpdate?.name ? 'Update Enquiry' : 'Create Enquiry'}</DialogTitle>
        <DialogContent sx={{ minWidth: '450px' }}>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column">
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label="Name"
                    {...register('name', {
                      required: { value: true, message: 'Enquiry name is required' },
                    })}
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    sx={{ width: '100%', mb: 2 }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    type="text"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="phone"
                    label="Phone"
                    {...register('phone', {
                      required: { value: true, message: 'phone number is required' },
                      maxLength: { value: 15, message: 'phone number must be 15 characters' },
                      minLength: { value: 9, message: 'phone number must be 9 characters' },
                    })}
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    sx={{ width: '100%', mb: 2 }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    type="number"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="email"
                    label="Email"
                    {...register('email', {
                      required: { value: false, message: 'Enquiry email is required' },
                    })}
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    sx={{ width: '100%', mb: 2 }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    type="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="message"
                    label="Message"
                    {...register('message', {
                      required: { value: true, message: 'message is required' },
                    })}
                    error={!!errors.message}
                    helperText={errors?.message?.message}
                    type="textarea"
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '100%', mb: 2 }}
                  />
                </Grid>
                {isUpdate?._id && (
                  <Grid item xs={12} sx={{ padding: 0 }}>
                    <div>
                      <TextField
                        id="outlined-select-verified"
                        {...register('status', {
                          required: { value: false, message: 'Status is required' },
                        })}
                        select
                        fullWidth
                        label="Status"
                        size="small"
                        name="status"
                        value={status}
                        defaultValue={status || ''}
                        onChange={handleStatusChange}
                        InputLabelProps={{ shrink: true }}
                      >
                        {statusOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </Grid>
                )}
              </Grid>
              <DialogActions sx={{ mt: 2 }}>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    padding: '5px 20px',
                  }}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                  autoFocus
                >
                  {isUpdate?._id ? 'Update ' : 'Create '}
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
