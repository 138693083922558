export const useFormatTime = (time) => {
  if (time) {
    const date = new Date();
    const [hours, minutes] = time?.split(':');
    date.setHours(hours);
    date.setMinutes(minutes);
    return date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  }
  return null;
};
