import { Box, Button, Card, CircularProgress, Divider, Link, Paper, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEnquiry } from '../../../server/api/enquiry';
import Iconify from '../../../components/iconify/Iconify';
import TextTruncate from '../../../components/Typography/TextTruncate';

function DashboardEnquiryList() {
  const dispatch = useDispatch();
  const { enquiryList, loading } = useSelector((state) => ({
    enquiryList: state.enquiry.enquiryList,
    loading: state.enquiry.loading,
  }));

  useEffect(() => {
    const requestData = {
      page: 1,
      limit: 6,
      search: '',
      sortBy: '',
      sortDirection: '',
      dispatch,
      sortDate: '',
      isDropDown: true,
    };
    dispatch(getAllEnquiry(requestData));
  }, [dispatch]);
  return (
    <div>
      <Card sx={{ padding: 1, minHeight: 460 }}>
        <Typography component={''} sx={{ padding: 2, fontSize: ' 1.125rem;', fontWeight: 700 }} variant="subtitle2">
          Enquiries
        </Typography>
        {loading ? (
          <Paper
            sx={{
              textAlign: 'center',
              width: '100%',
              backgroundColor: 'transparent',
              py: 3,
            }}
          >
            <CircularProgress color="success" />
          </Paper>
        ) : (
          <>
            {enquiryList?.list?.map((i) => (
              <>
                <Stack direction="row" alignItems="center" spacing={2} sx={{ padding: 1 }}>
                  <Box sx={{ minWidth: 240, flexGrow: 1 }}>
                    <Link color="inherit" variant="subtitle2" underline="hover" noWrap>
                      {i?.name}
                    </Link>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                      <TextTruncate text={i?.message} width={300} />
                    </Typography>
                  </Box>

                  <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
                    {moment(i?.createdAt).startOf('hour').fromNow()}
                  </Typography>
                </Stack>
                <Divider />
              </>
            ))}
          </>
        )}
        <Divider />

        <Box sx={{ px: 0, pt: 2, textAlign: 'right' }}>
          <Link href="/dashboard/enquiry" sx={{ textDecoration: 'none' }}>
            <Button size="small" color="inherit" endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}>
              View all
            </Button>
          </Link>
        </Box>
      </Card>
    </div>
  );
}

export default DashboardEnquiryList;
