import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { del, get, post, put } from './http';

export const createSession = createAsyncThunk('sessions/create', async ({ state, dispatch, handleClose }) => {
  try {
    const response = await post(`/session/admin/new`, state);
    if (response) {
      handleClose();
      dispatch(activeSnack({ type: 'success', message: 'Create Session SuccessFully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message || 'something went wrong' }));
    throw error?.response?.data?.message;
  }
});

export const getAllSession = createAsyncThunk('sessions/list', async ({ page, limit, search, dispatch }) => {
  try {
    const URL = `/session/admin/all?page=${page && page}&limit=${limit && limit}&search=${search && search}`;
    const response = await get(URL);
    if (response) {
      return {
        list: response?.sessions,
        total: response?.total,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const deleteSession = createAsyncThunk(
  'sessions/delete',
  async ({ sessionId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/session/admin/${sessionId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Session deleted Successfully' }));
        return sessionId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSessionDetails = createAsyncThunk('sessions/single', async ({ sessionId, dispatch }) => {
  try {
    const response = await get(`/session/admin/${sessionId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateSessionDetails = createAsyncThunk(
  'sessions/update',
  async ({ state, sessionId, dispatch, handleClose }) => {
    try {
      const response = await put(`/session/admin/${sessionId}`, state);
      if (response) {
        handleClose();
        dispatch(activeSnack({ type: 'success', message: 'Update Session SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message || 'something went wrong' }));
      throw error?.response?.data?.message;
    }
  }
);
