import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { get, post, del, put } from './http';

export const createHoliday = createAsyncThunk('holiday/create', async ({ state, dispatch, handleClose }) => {
  try {
    const response = await post(`/holiday/admin/new`, state);
    if (response) {
      handleClose();
      dispatch(activeSnack({ type: 'success', message: 'Create Holiday SuccessFully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message || 'something went wrong' }));
    throw error?.response?.data?.message;
  }
});

export const getAllHoliday = createAsyncThunk(
  'holiday/list',
  async ({ page, limit, search, dispatch, sortDate: { from, to } }) => {
    try {
      const URL = `/holiday/admin/all?page=${page && page}&limit=${limit && limit}&search=${search && search}&limit=${
        limit && limit
      }&fromDate=${from && from}&toDate=${to && to}`;

      // &sortBy=${sortBy && sortBy}&sortDirection=${sortDirection && sortDirection}`;

      const response = await get(URL);
      if (response) {
        return {
          list: response?.holidays,
          total: response?.total,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteHoliday = createAsyncThunk(
  'holiday/delete',
  async ({ holidayId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/holiday/admin/${holidayId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Holiday deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getHolidayDetails = createAsyncThunk('holiday/single', async ({ holidayId, dispatch }) => {
  try {
    const response = await get(`/holiday/admin/${holidayId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateHolidayDetails = createAsyncThunk(
  'holiday/update',
  async ({ state, holidayId, dispatch, handleClose }) => {
    try {
      const response = await put(`/holiday/admin/${holidayId}`, state);
      if (response) {
        handleClose();
        dispatch(activeSnack({ type: 'success', message: 'Update Holiday SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message || 'something went wrong' }));
      throw error?.response?.data?.message;
    }
  }
);
