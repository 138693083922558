import { createSlice } from '@reduxjs/toolkit';
import { createEnquiry, deleteEnquiry, getAllEnquiry, getEnquiryDetails, updateEnquiryDetails } from '../api/enquiry';
import { covertToJSON } from '../../utils/stateToJson';

export const enquirySlice = createSlice({
  name: 'enquiry',
  initialState: {
    loading: false,
    error: {},
    // enquiry
    enquiryList: {},
    enquiryDropList: [],
    enquiryDetails: {},
  },
  reducers: {
    clearEnquiryError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create enquiry details
    [createEnquiry.pending]: (state) => {
      state.loading = true;
    },
    [createEnquiry.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.enquiryList;
      const modifiedEnquiryList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [action.payload, ...jsonState?.list] : [action.payload],
      };
      state.loading = false;
      state.enquiryDetails = action.payload;
      state.enquiryList = modifiedEnquiryList;

      state.error = {};
    },
    [createEnquiry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all enquiry
    [getAllEnquiry.pending]: (state) => {
      state.loading = true;
    },
    [getAllEnquiry.fulfilled]: (state, action) => {
      state.loading = false;
      state.enquiryList = action.payload;
      state.error = {};
    },
    [getAllEnquiry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single enquiry details
    [getEnquiryDetails.pending]: (state) => {
      state.loading = true;
    },
    [getEnquiryDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.enquiryDetails = action.payload;
      state.error = {};
    },
    [getEnquiryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update enquiry details
    [updateEnquiryDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateEnquiryDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.enquiryList;
      const modifiedEnquiryList = {
        ...jsonState,
        list: jsonState.list?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
        // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
      };
      state.loading = false;
      state.enquiryDetails = action.payload;
      state.enquiryList = modifiedEnquiryList;
      state.error = {};
    },
    [updateEnquiryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteEnquiry.pending]: (state) => {
      state.loading = true;
    },
    [deleteEnquiry.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.enquiryList;
      const modifiedEnquiryList = {
        ...jsonState,
        list: jsonState.list?.filter((enquiry) => enquiry._id !== action.payload),
      };
      state.loading = false;
      state.enquiryList = modifiedEnquiryList;
      state.error = {};
    },
    [deleteEnquiry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearEnquiryError } = enquirySlice.actions;

export default enquirySlice.reducer;
