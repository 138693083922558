import { createSlice } from '@reduxjs/toolkit';
import { getApp } from '../api/app';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    loading: false,
    error: {},
    app: {},
  },
  reducers: {},
  extraReducers: {
    [getApp.pending]: (state) => {
      state.loading = true;
    },
    [getApp.fulfilled]: (state, action) => {
      state.loading = false;
      state.app = action.payload;
    },
    [getApp.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default appSlice.reducer;
