import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// @mui
import { styled } from '@mui/material/styles';

//
import Header from './header';
import Nav from './sidebarNav';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { sideBar } = useSelector((state) => ({
    sideBar: state.common.sideBar,
  }));

  const [open, setOpen] = useState(false);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <div id={`sidebar-${sideBar ? 'active' : 'inActive'}`}>
        <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      </div>

      <Main id={`main-body-sidebar-${sideBar ? 'active' : 'inActive'}`}>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
