import { createSlice } from '@reduxjs/toolkit';
import {
  createAppointment,
  getAllAppointment,
  getAppointmentDetails,
  deleteAppointment,
  updateAppointmentDetails,
  getAppointmentSlots,
} from '../api/appointment';
import { covertToJSON } from '../../utils/stateToJson';

export const appointmentsSlice = createSlice({
  name: 'appointment',
  initialState: {
    loading: false,
    error: {},
    appointmentsList: {},
    appointmentsDropList: [],
    appointmentsDetails: {},
    slotList: {},
  },
  reducers: {
    clearAppointmentError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create appointments details
    [createAppointment.pending]: (state) => {
      state.loading = true;
    },
    [createAppointment.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.appointmentsList;
      const modifiedAppointmentsList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [action.payload, ...jsonState?.list] : [action.payload],
      };
      state.loading = false;
      state.appointmentsDetails = action.payload;
      state.appointmentsList = modifiedAppointmentsList;

      state.error = {};
    },
    [createAppointment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all appointments
    [getAllAppointment.pending]: (state) => {
      state.loading = true;
    },
    [getAllAppointment.fulfilled]: (state, action) => {
      state.loading = false;
      state.appointmentsList = action.payload;
      state.error = {};
    },
    [getAllAppointment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single appointments details
    [getAppointmentDetails.pending]: (state) => {
      state.loading = true;
    },
    [getAppointmentDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.appointmentsDetails = action.payload;
      state.error = {};
    },
    [getAppointmentDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update appointments details
    [updateAppointmentDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateAppointmentDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.appointmentsDetails = action.payload;
      state.error = {};
    },
    [updateAppointmentDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteAppointment.pending]: (state) => {
      state.loading = true;
    },
    [deleteAppointment.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.appointmentsList;
      const modifiedAppointmentList = {
        ...jsonState,
        list: jsonState.list?.filter((appointment) => appointment._id !== action.payload),
      };
      state.loading = false;
      state.appointmentsList = modifiedAppointmentList;
      state.error = {};
    },
    [deleteAppointment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all appointments slot
    [getAppointmentSlots.pending]: (state) => {
      state.loading = true;
    },
    [getAppointmentSlots.fulfilled]: (state, action) => {
      state.loading = false;
      state.slotList = action.payload;
      state.error = {};
    },
    [getAppointmentSlots.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearAppointmentError } = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
