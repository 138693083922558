import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

// @mui
import { Stack, Alert, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// components
import { colors } from '../../../theme/colors';
import { createStaff } from '../../../server/api/staff';

CreateStaffsDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default function CreateStaffsDialog({ open, setOpen }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => ({
    loading: state.staffs.loading,
    error: state.staffs.error,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {},
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleClear = () => {
    reset();
  };

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: { ...formData },
      dispatch,
      handleClose,
      handleClear,
    };

    dispatch(createStaff(credentials));
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{'Create Staff'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column" spacing={2}>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  required
                  name="name"
                  label="Name"
                  {...register('name', {
                    required: { value: true, message: 'name is required' },
                  })}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="designation"
                  label="Designation"
                  {...register('designation', {
                    required: {
                      value: false,
                      message: 'Designation is required',
                    },
                  })}
                  sx={{ width: '100%', mb: 2 }}
                  error={!!errors?.designation}
                  helperText={errors?.designation?.message}
                  size="small"
                  type="text"
                />
                <TextField
                  name="phone"
                  label="Phone "
                  {...register('phone', {
                    required: { value: false, message: 'Phone is required' },
                    maxLength: { value: 15, message: 'Phone number must be 15 characters' },
                    minLength: { value: 9, message: 'Phone number must be 9 characters' },
                  })}
                  error={!!errors.phone}
                  helperText={errors?.phone?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  type="number"
                />
              </Stack>

              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="email"
                  label="Email Address"
                  {...register('email', {
                    required: {
                      value: false,
                      message: 'email is required',
                    },
                  })}
                  sx={{ width: '100%', mb: 2 }}
                  error={!!errors?.email}
                  helperText={errors?.email?.message}
                  size="small"
                  type="email"
                />
              </Stack>

              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    padding: '5px 20px',
                  }}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                  autoFocus
                >
                  Create
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
