import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Divider,
  Chip,
  Box,
  Alert,
  TextField,
  Avatar,
  MenuItem,
} from '@mui/material';
import { EmailRounded, KeyboardBackspace, PhoneRounded } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
// components
import Iconify from '../../components/iconify';
import Label from '../../components/label/Label';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
import { colors } from '../../theme/colors';
import { getAppointmentDetails, updateAppointmentDetails } from '../../server/api/appointment';
import { getAllSession } from '../../server/api/sessions';
import GoogleMapsList from '../../segments/@dashboard/common/LocationDropDown';
import { useFormatTime } from '../../hooks/useTimeFormatter';

export default function AppointmentDetailsPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { loading, error, appointmentsDetails, sessionLoading, sessionsList } = useSelector((state) => ({
    loading: state.appointment.loading,
    appointmentsDetails: state.appointment.appointmentsDetails,
    error: state.appointment.error,
    sessionLoading: state.sessions.loading,
    sessionsList: state.sessions.sessionsList,
  }));

  const appointmentId = location.state;

  const [placeName, setPlaceName] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: '',
    longitude: '',
  });
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    const credentials = {
      appointmentId,
      dispatch,
    };
    dispatch(getAppointmentDetails(credentials));
  }, [dispatch, appointmentId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      location: '',
      date: '',
      remarks: '',
      address: '',
      status: '',
    },
  });

  useEffect(() => {
    reset({
      name: appointmentsDetails?.name,
      email: appointmentsDetails?.email,
      phone: appointmentsDetails?.phone,
      // location: appointmentsDetails?.location,
      date: moment(appointmentsDetails?.date).format('YYYY-MM-DD') || '',
      remarks: appointmentsDetails?.remarks,
      status: appointmentsDetails?.status,
      address: appointmentsDetails?.address,
    });
  }, [reset, appointmentsDetails]);

  // Session
  const [session, setSession] = useState('Search Session');
  const [selectedSessionId, setSelectedSessionId] = useState();
  const [sessionSearchText, setSessionSearchText] = useState('');

  useEffect(() => {
    const credentials = {
      search: sessionSearchText,
      dispatch,
    };
    dispatch(getAllSession(credentials));
  }, [sessionSearchText, dispatch]);

  function handlerFinalValueSession(event) {
    setSession(event.label);
  }

  function formatTime(time) {
    const formatTime = (time) => {
      const date = new Date();
      const [hours, minutes] = time.split(':');
      date.setHours(hours);
      date.setMinutes(minutes);
      return date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    };

    return formatTime(time);
  }

  const optionSession = [
    {
      options: (sessionsList?.list || [])?.map((results) => ({
        label: `${formatTime(results?.timeFrom)} - ${formatTime(results?.timeTo)}`,
        value: results?._id,
      })),
    },
  ];

  const handleEntersSession = (textEntered) => {
    setSessionSearchText(textEntered);
  };
  const timeFrom = useFormatTime(appointmentsDetails?.session?.timeFrom);
  const timeTo = useFormatTime(appointmentsDetails?.session?.timeTo);

  useEffect(() => {
    setSession(`${timeFrom} - ${timeTo}`);
    setSelectedSessionId(appointmentsDetails?.session?._id);
    setSelectedLocation({
      longitude: appointmentsDetails?.location && appointmentsDetails?.location[0],
      latitude: appointmentsDetails?.location && appointmentsDetails?.location[1],
    });
  }, [appointmentsDetails, timeFrom, timeTo]);

  const onSubmit = async (formData, e) => {
    e?.preventDefault();

    const credentials = {
      state: {
        ...formData,
        session: selectedSessionId,
        // status: statusValue,
        location: [selectedLocation.longitude, selectedLocation.latitude],
      },
      dispatch,
      appointmentId: appointmentsDetails?._id,
      navigate: isSaved ? navigate('/dashboard/appointments') : '',
    };
    dispatch(updateAppointmentDetails(credentials));
  };

  useEffect(() => {
    if (appointmentsDetails?.status) {
      setStatus(appointmentsDetails?.status);
    }
  }, [appointmentsDetails]);

  const updateAppointmentLocation = (lat, lng) => {
    if (lat && lng) {
      const credentials = {
        state: {
          ...appointmentsDetails,
          session: appointmentsDetails?.session?._id,
          location: [lng, lat],
          // status: status,
        },
        dispatch,
        appointmentId: appointmentsDetails?._id,
      };
      dispatch(updateAppointmentDetails(credentials));
    }
  };

  const statusData = appointmentsDetails?.status;

  const handleColor = (color) => {
    switch (color) {
      case 'new':
        return 'primary';
      case 'contacted':
        return 'info';
      case 'confirmed':
        return 'success';
      case 'pending':
        return 'primary';
      case 'cancelled':
        return 'error';
      default:
        return 'primary'; // Replace 'default-color' with the color you want to use for the default case.
    }
  };

  const [status, setStatus] = useState('new');

  const statusOptions = [
    { label: 'New', value: 'new' },
    { label: 'Contacted', value: 'contacted' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Pending', value: 'pending' },
  ];

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    setStatus(appointmentsDetails?.status);
  }, [appointmentsDetails]);

  return (
    <>
      <MetaHelmet title="Users" />
      <Container>
        <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Stack direction="row" alignItems="center">
              <KeyboardBackspace onClick={() => navigate(-1)} sx={{ cursor: 'pointer', mr: 2, mb: 1 }} />
              <Typography variant="h4" gutterBottom>
                Appointment Details
              </Typography>
            </Stack>
            <Button
              variant="contained"
              color="inherit"
              sx={{ backgroundColor: colors.green, color: 'white' }}
              startIcon={<Iconify icon="eva:save-fill" />}
              type="submit"
              onClick={() => setIsSaved(true)}
            >
              Save
            </Button>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Card sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <Stack>
                <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
                  <Stack direction={'row'}>
                    <Avatar variant="rounded" sx={{ cursor: 'pointer', mr: 2 }} src={''} />
                    <Stack spacing={0.5} sx={{ mt: '-5px' }}>
                      <Typography fontWeight={700}>{appointmentsDetails?.name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        <EmailRounded sx={{ color: grey[500], mb: '-3px', fontSize: '16px' }} />{' '}
                        {appointmentsDetails?.email}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <PhoneRounded sx={{ color: grey[500], mb: '-3px', fontSize: '16px' }} />{' '}
                        {appointmentsDetails?.phone}
                      </Typography>
                      <Typography>
                        <Label color={handleColor(statusData)}>{statusData}</Label>
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography mb={0.5} variant="caption">
                      Appointment Date
                    </Typography>
                    <Chip sx={{ bgcolor: colors.light }} label={moment(appointmentsDetails?.date).format('ll')} />
                  </Stack>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Stack mb={2}>
                    <Typography variant="overline">Address</Typography>
                    <Typography variant="body2">{appointmentsDetails?.address}</Typography>
                  </Stack>
                  <Divider />{' '}
                  <Stack mb={2} mt={2}>
                    <Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
                      <Box sx={{ width: '100%', mb: 1 }}>
                        <Typography variant="overline">Location</Typography>
                        <Typography variant="body2">{placeName}</Typography>
                      </Box>
                      <Box sx={{ width: '100%', mb: 1 }}>
                        <Typography variant="caption">Change Location</Typography>
                        <GoogleMapsList
                          sx={{
                            mt: 1,
                          }}
                          variant="filled"
                          setPlaceName={setPlaceName}
                          selectedLocation={selectedLocation}
                          setSelectedLocation={setSelectedLocation}
                          updateAppointmentLocation={updateAppointmentLocation}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography variant="overline">Remarks</Typography>
                    <Typography variant="body2">{appointmentsDetails?.remarks}</Typography>
                  </Stack>
                </Box>
                <Divider />{' '}
                <Box sx={{ p: 2 }}>
                  <Stack direction={'column'} maxWidth={'250px'} justifyContent={'space-between'} alignItems={'start'}>
                    <Typography variant="overline" color={'GrayText'} gutterBottom>
                      Session Details
                    </Typography>
                    <Typography variant="body2" textTransform={'capitalize'}>
                      On {appointmentsDetails?.session?.day}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      At {timeFrom} - {timeTo}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
              >
                <Box />
                <Chip label={`Appointment Created In : ${moment(appointmentsDetails?.createdAt).format('ll')}`} />
              </Stack>
            </Card>

            <Card sx={{ width: '100%', p: 3 }}>
              <Typography variant="p" gutterBottom>
                Edit Details
              </Typography>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="date"
                  label="Date"
                  {...register('date', {
                    required: { value: false },
                  })}
                  error={!!errors.date}
                  defaultValue={moment(appointmentsDetails?.date).format('YYYY-MM-DD') || ''}
                  helperText={errors?.date?.message}
                  sx={{ mb: 3 }}
                  fullWidth
                  size="small"
                  type="date"
                  required
                  InputLabelProps={{ shrink: true }}
                />
                <div
                  className="my-new-select"
                  style={{
                    width: '100%',
                    marginBottom: 10,
                  }}
                >
                  <ReactSelect
                    onInputChange={handleEntersSession}
                    placeholder={session}
                    value={session}
                    options={optionSession}
                    classNamePrefix="select2-selection"
                    onChange={(e) => handlerFinalValueSession(e)}
                    type="text"
                    isLoading={sessionLoading}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  />
                </div>
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="name"
                  label="Name"
                  {...register('name', {
                    required: { value: true, message: 'name is required' },
                  })}
                  required
                  fullWidth
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  sx={{ mb: 3 }}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name="phone"
                  label="Phone "
                  {...register('phone', {
                    required: { value: true, message: 'Phone is required' },
                  })}
                  fullWidth
                  required
                  error={!!errors.phone}
                  helperText={errors?.phone?.message}
                  sx={{ mb: 3 }}
                  size="small"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="email"
                  label="Email"
                  {...register('email', {
                    required: {
                      value: false,
                      message: 'email is required',
                    },
                  })}
                  fullWidth
                  sx={{ mb: 3 }}
                  error={!!errors?.email}
                  helperText={errors?.email?.message}
                  size="small"
                  type="email"
                  InputLabelProps={{ shrink: true }}
                />

                {/* <TextField
                  name="location"
                  label="Location"
                  {...register('location', {
                    required: { value: false, message: 'location is required' },
                  })}
                  error={!!errors.location}
                  helperText={errors?.location?.message}
                  sx={{ width: '100%', mb: 3 }}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                /> */}
              </Stack>

              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="address"
                  label="Address"
                  {...register('address', {
                    required: { value: true, message: 'address is required' },
                  })}
                  minRows={3}
                  fullWidth
                  maxRows={10}
                  multiline
                  required
                  error={!!errors.address}
                  helperText={errors?.address?.address}
                  type="textarea"
                  InputLabelProps={{ shrink: true }}
                  sx={{ mb: 3 }}
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="remarks"
                  label="Remarks"
                  {...register('remarks', {
                    required: { value: false, message: 'remarks is required' },
                  })}
                  minRows={2}
                  maxRows={10}
                  multiline
                  error={!!errors.remarks}
                  helperText={errors?.remarks?.remarks}
                  InputLabelProps={{ shrink: true }}
                  type="textarea"
                  sx={{ width: '100%', mb: 3 }}
                />
              </Stack>

              <TextField
                id="outlined-select-verified"
                {...register('status', {
                  required: { value: false, message: 'Status is required' },
                })}
                select
                fullWidth
                label="Status"
                size="small"
                value={status}
                defaultValue={status || ''}
                onChange={handleStatusChange}
                InputLabelProps={{ shrink: true }}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <LoadingButton
                sx={{
                  backgroundColor: colors.green,
                  color: 'white',
                  mt: 2,
                }}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
                color="info"
                loading={loading}
              >
                Update
              </LoadingButton>
            </Card>
          </Stack>
        </form>
      </Container>
    </>
  );
}
