import { createSlice } from '@reduxjs/toolkit';
import { createHoliday, getAllHoliday, getHolidayDetails, deleteHoliday, updateHolidayDetails } from '../api/holiday';
import { covertToJSON } from '../../utils/stateToJson';

export const holidaysSlice = createSlice({
  name: 'holiday',
  initialState: {
    loading: false,
    error: {},
    holidaysList: {},
    holidaysDropList: [],
    holidaysDetails: {},
  },
  reducers: {
    clearHolidayError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create holidays details
    [createHoliday.pending]: (state) => {
      state.loading = true;
    },
    [createHoliday.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.holidaysList;
      const modifiedHolidaysList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [action.payload, ...jsonState?.list] : [action.payload],
      };
      state.loading = false;
      state.holidaysDetails = action.payload;
      state.holidaysList = modifiedHolidaysList;

      state.error = {};
    },
    [createHoliday.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all holidays
    [getAllHoliday.pending]: (state) => {
      state.loading = true;
    },
    [getAllHoliday.fulfilled]: (state, action) => {
      state.error = {};
      state.loading = false;
      state.holidaysList = action.payload;
    },
    [getAllHoliday.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single holidays details
    [getHolidayDetails.pending]: (state) => {
      state.loading = true;
    },
    [getHolidayDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.holidaysDetails = action.payload;
      state.error = {};
    },
    [getHolidayDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update holidays details
    [updateHolidayDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateHolidayDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.holidaysList;
      const modifiedHolidayList = {
        ...jsonState,
        list: jsonState?.list?.map((holiday) => (holiday._id === action.payload?._id ? action.payload : holiday)),
      };
      state.holidaysDetails = action.payload;
      state.holidaysList = modifiedHolidayList;
      state.loading = false;
      state.error = {};
    },
    [updateHolidayDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteHoliday.pending]: (state) => {
      state.loading = true;
    },
    [deleteHoliday.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.holidaysList;
      const modifiedHolidayList = {
        ...jsonState,
        list: jsonState.list?.filter((holiday) => holiday._id !== action.payload),
      };
      state.loading = false;
      state.holidaysList = modifiedHolidayList;
      state.error = {};
    },
    [deleteHoliday.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearHolidayError } = holidaysSlice.actions;

export default holidaysSlice.reducer;
