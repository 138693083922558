import { createSlice } from '@reduxjs/toolkit';
import { createStaff, getAllStaff, getStaffDetails, updateStaffDetails, deleteStaff } from '../api/staff';
import { covertToJSON } from '../../utils/stateToJson';

export const staffsSlice = createSlice({
  name: 'staffs',
  initialState: {
    loading: false,
    error: {},
    // staff
    staffList: {},
    staffDropList: [],
    staffDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create staff details
    [createStaff.pending]: (state) => {
      state.loading = true;
    },
    [createStaff.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.staffList;
      const modifiedStaffList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [action.payload, ...jsonState?.list] : [action.payload],
      };
      state.loading = false;
      state.staffDetails = action.payload;
      state.staffList = modifiedStaffList;
      state.error = {};
    },
    [createStaff.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all staff
    [getAllStaff.pending]: (state) => {
      state.loading = true;
    },
    [getAllStaff.fulfilled]: (state, action) => {
      state.loading = false;
      state.staffList = action.payload;
      state.error = {};
    },
    [getAllStaff.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single staff details
    [getStaffDetails.pending]: (state) => {
      state.loading = true;
    },
    [getStaffDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.staffDetails = action.payload;
      state.error = {};
    },
    [getStaffDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update staff details
    [updateStaffDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateStaffDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.staffDetails = action.payload;
      state.error = {};
    },
    [updateStaffDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteStaff.pending]: (state) => {
      state.loading = true;
    },
    [deleteStaff.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.staffList;
      const modifiedStaffList = {
        ...jsonState,
        list: jsonState.list?.filter((staff) => staff._id !== action.payload),
      };
      state.loading = false;
      state.staffList = modifiedStaffList;
      state.error = {};
    },
    [deleteStaff.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = staffsSlice.actions;

export default staffsSlice.reducer;
