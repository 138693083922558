import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../theme/colors';
import { useFormatTime } from '../../hooks/useTimeFormatter';

function SessionCard({ classes, handleOpenPopup, task, day }) {
  const timeFrom = useFormatTime(task.timeFrom);
  const timeTo = useFormatTime(task.timeTo);

  return (
    <Card className={classes.card} sx={{}}>
      <CardActionArea sx={{}} onClick={() => handleOpenPopup({ ...task, day })}>
        <CardContent sx={{ p: 2 }}>
          <Typography variant="body2" component="p">
            {task.title}
          </Typography>
          <Typography color={colors.green} variant="body2" component="p" fontWeight={500}>
            {timeFrom} - {timeTo}
          </Typography>
          {/* <Typography variant="caption" component="p">
            session Added in <br /> {moment(task.createdAt).format('ll')}
          </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default SessionCard;
SessionCard.propTypes = {
  classes: PropTypes.any,
  handleOpenPopup: PropTypes.func,
  task: PropTypes.any,
  day: PropTypes.string,
};
