import { createSlice } from '@reduxjs/toolkit';
import { createLeave, getAllLeave, getLeaveDetails, deleteLeave, updateLeaveDetails } from '../api/leave';
import { covertToJSON } from '../../utils/stateToJson';

export const leavesSlice = createSlice({
  name: 'leave',
  initialState: {
    loading: false,
    error: {},
    leavesList: {},
    leavesDropList: [],
    leavesDetails: {},
  },
  reducers: {
    clearLeaveError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create leaves details
    [createLeave.pending]: (state) => {
      state.loading = true;
    },
    [createLeave.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.leavesList;
      const modifiedLeavesList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [action.payload, ...jsonState?.list] : [action.payload],
      };
      state.loading = false;
      state.leavesDetails = action.payload;
      state.leavesList = modifiedLeavesList;

      state.error = {};
    },
    [createLeave.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all leaves
    [getAllLeave.pending]: (state) => {
      state.loading = true;
    },
    [getAllLeave.fulfilled]: (state, action) => {
      state.error = {};
      state.loading = false;
      state.leavesList = action.payload;
    },
    [getAllLeave.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single leaves details
    [getLeaveDetails.pending]: (state) => {
      state.loading = true;
    },
    [getLeaveDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.leavesDetails = action.payload;
      state.error = {};
    },
    [getLeaveDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update leaves details
    [updateLeaveDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateLeaveDetails.fulfilled]: (state, action) => {
      state.loading = false;
      const jsonState = covertToJSON(state)?.leavesList;
      const modifiedLeavesList = {
        ...jsonState,
        list: jsonState?.list?.map((item) => (item?._id === action.payload?._id ? action.payload : item)),
      };
      state.leavesList = modifiedLeavesList;
      state.error = {};
    },
    [updateLeaveDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteLeave.pending]: (state) => {
      state.loading = true;
    },
    [deleteLeave.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.leavesList;
      const modifiedLeaveList = {
        ...jsonState,
        list: jsonState.list?.filter((leave) => leave._id !== action.payload),
      };
      state.loading = false;
      state.leavesList = modifiedLeaveList;
      state.error = {};
    },
    [deleteLeave.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearLeaveError } = leavesSlice.actions;

export default leavesSlice.reducer;
