import React from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Stack, Typography } from '@mui/material';
import Iconify from '../../../components/iconify';

// Styled component for the icon container
const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// Prop types for the AppWidgetSummary component
AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  todayCount: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

// Main AppWidgetSummary component
export default function AppWidgetSummary({ title, todayCount, total, icon, color = 'primary', sx, ...other }) {
  return (
    <Card
      // Card styles and custom styles using the `sx` prop
      sx={{
        minHeight: 262,
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      {/* Icon container */}
      <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
        }}
      >
        {/* Icon component */}
        <Iconify icon={icon} width={24} height={24} />
      </StyledIcon>

      {/* Render different content based on the `title` prop */}
      {title === 'Attendance' ? (
        // Render attendance-related information
        <>
          <Typography>{title}</Typography>
          <Stack direction={'row'} sx={{ justifyContent: 'center' }} mt={3}>
            <Stack mx={2}>
              <Typography>
                Present
                <Typography variant="h4">{total ?? 0}</Typography>
              </Typography>
            </Stack>
            <Stack mx={2}>
              <Typography>
                Leave
                <Typography variant="h4">{todayCount ?? 0}</Typography>
              </Typography>
            </Stack>
          </Stack>
        </>
      ) : title === 'Staffs' ? (
        // Render staff-related information
        <>
          <Typography>{title}</Typography>
          <Stack direction={'colum'} sx={{ justifyContent: 'center' }} mt={3}>
            <Stack mx={2}>
              <Typography>
                Total
                <Typography variant="h4">{total ?? 0}</Typography>
              </Typography>
            </Stack>
          </Stack>
        </>
      ) : (
        // Render information with total and today counts
        <>
          <Typography>{title}</Typography>
          <Stack direction={'row'} sx={{ justifyContent: 'center' }} mt={3}>
            <Stack mx={2}>
              <Typography>
                Total
                <Typography variant="h4">{total ?? 0}</Typography>
              </Typography>
            </Stack>
            <Stack mx={2}>
              <Typography>
                Today
                <Typography variant="h4">{todayCount ?? 0}</Typography>
              </Typography>
            </Stack>
          </Stack>
        </>
      )}

      {/* Uncomment the code below to show the alternative content */}
      {/* {todayCount ? (
        <>
          <Typography>{title}</Typography>
          <Stack sx={{ padding: 1 }}>{total}</Stack>
        </>
      ) : (
        <>
          <Typography variant="h3">{total ? fShortenNumber(total) : 0}</Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            {title}
          </Typography>
        </>
      )} */}
    </Card>
  );
}
