import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';

// @mui
import {
  Stack,
  Alert,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// components
import { useFormatTime as UseFormatTime } from '../../../hooks/useTimeFormatter';
import { colors } from '../../../theme/colors';
import { createAppointment, getAppointmentSlots } from '../../../server/api/appointment';
import { getAllSession } from '../../../server/api/sessions';
import GoogleMapsList from '../common/LocationDropDown';
import { clearAppointmentError } from '../../../server/store/appointments';

CreateAppointmentDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default function CreateAppointmentDialog({ open, setOpen }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();
  const location = useLocation();

  const { loading, error, sessionLoading, sessionsList, slotList, slotLoading } = useSelector((state) => ({
    loading: state.appointment.loading,
    error: state.appointment.error,
    sessionLoading: state.sessions.loading,
    sessionsList: state.sessions.sessionsList,
    slotList: state.appointment.slotList,
    slotLoading: state.appointment.loading,
  }));

  const handleClose = () => {
    setOpen(false);
    dispatch(clearAppointmentError());
  };

  const initial = {
    date: '',
    address: '',
    email: '',
    location: {},
    name: '',
    phone: '',
    remarks: '',
    session: '',
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: initial,
  });

  const [selectedLocation, setSelectedLocation] = useState({
    longitude: '',
    latitude: '',
  });

  const [selectedDate, setSelectedDate] = useState('');

  const [selectedSessionId, setSelectedSessionId] = useState('');
  const [selectedSession, setSelectedSession] = useState('');

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getAllSession(credentials));
    dispatch(getAppointmentSlots(credentials));
  }, [dispatch]);

  const [availableSlotsData, setAvailableSlots] = useState([]);

  const [isManual, setIsManual] = useState(false);
  const [slot, setSlot] = useState('');

  const handleChange = (event) => {
    setSlot(event.target.value);
    setSelectedSessionId(event.target.value);
  };

  const handleLabel = (item) => {
    const timeFrom = UseFormatTime(item?.timeFrom);
    const timeTo = UseFormatTime(item?.timeTo);
    return `${timeFrom} - ${timeTo}`;
  };

  useEffect(() => {
    setAvailableSlots(slotList?.list?.filter((i) => i.date === selectedDate));
  }, [selectedDate, slotList?.list]);

  useEffect(() => {
    setAvailableSlots(slotList?.list);
  }, [slotList?.list]);

  const handleClear = () => {
    reset(initial);
    setSelectedDate('');
    setSelectedLocation({
      longitude: '',
      latitude: '',
    });
    setSelectedSessionId('');
    setSelectedSession('');
  };

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: {
        ...formData,
        session: selectedSessionId,
        location: [selectedLocation.longitude, selectedLocation.latitude],
      },
      dispatch,
      handleClose,
      handleClear,
      location,
    };
    dispatch(createAppointment(credentials));
  };
  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{'Create Appointment'}</DialogTitle>
        <DialogContent>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
            <FormControlLabel
              control={<IOSSwitch sx={{ mx: 1 }} checked={isManual} onChange={(e) => setIsManual(e.target.checked)} />}
              label="Manual"
            />
          </Stack>

          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column" spacing={2}>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>
              {isManual ? (
                <Stack width={'100%'} spacing={2} direction={'row'}>
                  <TextField
                    name="date"
                    label="Date"
                    {...register('date', {
                      required: { value: false },
                    })}
                    error={!!errors.date}
                    helperText={errors?.date?.message}
                    sx={{ width: '100%', mb: 2 }}
                    size="small"
                    type="date"
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                  <Autocomplete
                    disablePortal
                    id="list-session"
                    loading={sessionLoading}
                    options={(sessionsList?.list || [])?.map((item) => ({
                      id: item?._id,
                      label: handleLabel(item),
                    }))}
                    sx={{ width: '100%', mb: 2 }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedSessionId(newValue?.id);
                        setSelectedSession(newValue?.label);
                      } else {
                        setSelectedSessionId(null);
                        setSelectedSession(null);
                      }
                    }}
                    value={selectedSession}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Session"
                        {...register('session', {
                          required: { value: true, message: 'Session is required' },
                        })}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Stack>
              ) : (
                <Stack width={'100%'} spacing={2} direction={'row'}>
                  <Autocomplete
                    disablePortal
                    id="slot-list-date"
                    loading={slotLoading}
                    options={(slotList?.list?.filter((i) => i.isHoliday === false) || [])?.map((item, key) => ({
                      id: key + 1,
                      label: item?.date,
                    }))}
                    sx={{ width: '100%', mb: 2 }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedDate(newValue?.label);
                      }
                    }}
                    value={selectedDate}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...register('date', {
                          required: { value: false },
                        })}
                        name="date"
                        {...params}
                        required={isManual}
                        label="Date"
                      />
                    )}
                  />
                  <FormControl required={isManual} fullWidth size="small">
                    <InputLabel id="demo-slot-select-label">Slot</InputLabel>
                    <Select
                      labelId="demo-slot-select-label"
                      id="demo-slot-select"
                      value={slot}
                      label="Slot"
                      onChange={handleChange}
                    >
                      {selectedDate?.length <= 0 && (
                        <MenuItem value={''} disabled>
                          Select a date first
                        </MenuItem>
                      )}
                      {(
                        (selectedDate?.length && availableSlotsData && availableSlotsData[0]?.availableSessions) ||
                        []
                      )?.map((item, key) => (
                        <MenuItem value={item?.session} key={key}>
                          {handleLabel(item)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              )}

              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="name"
                  label="Name"
                  required
                  {...register('name', {
                    required: { value: true, message: 'name is required' },
                  })}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="phone"
                  label="Phone "
                  required
                  {...register('phone', {
                    required: { value: true, message: 'Phone number is requied' },
                    maxLength: { value: 15, message: 'Phone number must be 15 characters' },
                    minLength: { value: 9, message: 'Phone number must be 9 characters' },
                  })}
                  error={!!errors.phone}
                  helperText={errors?.phone?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  type="number"
                />
                <TextField
                  name="email"
                  label="Email"
                  {...register('email', {
                    required: {
                      value: false,
                      message: 'email is required',
                    },
                  })}
                  sx={{ width: '100%', mb: 2 }}
                  error={!!errors?.email}
                  helperText={errors?.email?.message}
                  size="small"
                  type="email"
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="address"
                  label="Address"
                  {...register('address', {
                    required: { value: true, message: 'address is required' },
                  })}
                  required
                  error={!!errors.address}
                  helperText={errors?.address?.address}
                  type="textarea"
                  sx={{ width: '100%', mb: 2 }}
                />
              </Stack>

              {/* <Stack spacing={2} direction={'row'}>
                <GoogleMapsList selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} />
              </Stack> */}

              <Stack spacing={2} direction={'row'}>
                <GoogleMapsList selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} />
              </Stack>

              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="remarks"
                  label="Remarks"
                  {...register('remarks', {
                    required: { value: false, message: 'remarks is required' },
                  })}
                  error={!!errors.remarks}
                  helperText={errors?.remarks?.remarks}
                  type="textarea"
                  sx={{ width: '100%', mb: 2 }}
                />
              </Stack>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    padding: '5px 20px',
                  }}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                  autoFocus
                >
                  Create
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  })
);
// useEffect(() => {
//   const convertData = () => {
//     const convertedArray = slotList?.list?.reduce((acc, { date, availableSessions }) => {
//       const convertedSessions = availableSessions?.map(({ session, timeFrom, timeTo }) => ({
//         date,
//         _id: session,
//         timeFrom,
//         timeTo,
//       }));

//       return [...acc, ...convertedSessions];
//     }, []);

//     setAvailableSlots(convertedArray);
//   };

//   convertData();
// }, [slotList?.list]);
