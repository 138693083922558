import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { get } from './http';

export const getApp = createAsyncThunk('app/list', async ({ dispatch }) => {
  try {
    const response = await get(`/admin/app`);
    if (response.isSuccess) {
      return response?.app;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message || 'something went wrong' }));
    throw error?.response?.data?.message;
  }
});
