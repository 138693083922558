import { createSlice } from '@reduxjs/toolkit';
import { getDashboardDatas } from '../api/dashboard';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    error: {},
    loading: false,
    dashboardData: {},
  },
  reducers: {
    clearDashboardError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [getDashboardDatas.pending]: (state) => {
      state.loading = true;
    },
    [getDashboardDatas.fulfilled]: (state, action) => {
      state.loading = false;
      state.dashboardData = action.payload;
      state.error = {};
    },
    [getDashboardDatas.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export default dashboardSlice.reducer;
