import { createSlice } from '@reduxjs/toolkit';

import { createReview, getAllReview, getReviewDetails, updateReviewDetails, deleteReview } from '../api/Review';

const covertToJSON = (data) => JSON.parse(JSON.stringify(data));

export const reviewSlice = createSlice({
  name: 'review',
  initialState: {
    loading: false,
    error: {},
    // review
    reviewList: {},
    reviewDropList: [],
    reviewDetails: {},
  },
  reducers: {
    clearReviewError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create review details
    [createReview.pending]: (state) => {
      state.loading = true;
    },
    [createReview.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.reviewList;
      const modifiedReviewList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [action.payload, ...jsonState?.list] : [action.payload],
      };
      state.loading = false;
      state.reviewDetails = action.payload;
      state.reviewList = modifiedReviewList;
      state.error = {};
    },
    [createReview.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all review
    [getAllReview.pending]: (state) => {
      state.loading = true;
    },
    [getAllReview.fulfilled]: (state, action) => {
      state.loading = false;
      state.reviewList = action.payload;
      state.error = {};
    },
    [getAllReview.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single review details
    [getReviewDetails.pending]: (state) => {
      state.loading = true;
    },
    [getReviewDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.reviewDetails = action.payload;
      state.error = {};
    },
    [getReviewDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update review details
    [updateReviewDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateReviewDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.reviewDetails = action.payload;
      state.error = {};
    },
    [updateReviewDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteReview.pending]: (state) => {
      state.loading = true;
    },
    [deleteReview.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.reviewList;
      const modifiedReviewList = {
        ...jsonState,
        list: jsonState.list?.filter((review) => review._id !== action.payload),
      };
      state.loading = false;
      state.reviewList = modifiedReviewList;
      state.error = {};
    },
    [deleteReview.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearReviewError } = reviewSlice.actions;

export default reviewSlice.reducer;
