import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

import { LoadingButton } from '@mui/lab';
// @mui
import {
  Stack,
  Alert,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Autocomplete,
  Typography,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// components
import { colors } from '../../../theme/colors';
import { getAllAppointment } from '../../../server/api/appointment';
import { createReview, updateReviewDetails } from '../../../server/api/Review';

CreateReviewDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenReviewId: PropTypes.func,
  isUpdate: PropTypes.object,
};

export default function CreateReviewDialog({ open, setOpen, isUpdate, setOpenReviewId }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedAppointmentId, setSelectedAppointmentId] = useState('');
  const [selectedAppointment, setSelectedAppointment] = useState('');
  const [workQualityRating, setWorkQualityRating] = useState('');
  const [customerRelationRating, setCustomerRilationRating] = useState('');
  const [experienceRating, setExperienceRating] = useState('');
  const [appoinmentSearch, setAppoinmentSearch] = useState('');

  const handleAverageRating = () => {
    const ratings = Number(workQualityRating) + Number(customerRelationRating) + Number(experienceRating);
    const averageRating = ratings / 3;
    return averageRating;
  };
  const handleClose = () => {
    setOpen(false);
    setOpenReviewId({});
  };
  const dispatch = useDispatch();

  const { loading, error, appointmentList } = useSelector((state) => ({
    loading: state.appointment.loading,
    error: state.appointment.error,
    appointmentList: state.appointment?.appointmentsList,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      appoinment: '',
      name: '',
      phone: '',
      customerRelationRating: '',
      experienceRating: '',
      message: '',
      workQualityRating: '',
    },
  });

  useEffect(() => {
    if (isUpdate?._id) {
      reset({
        selectedAppointmentId: selectedAppointment,
        name: isUpdate?.name,
        phone: isUpdate?.phone,
        message: isUpdate?.message,
        customerRelationRating: isUpdate?.customerRelationRating,
        experienceRating: isUpdate?.experienceRating,
        workQualityRating: isUpdate?.workQualityRating,
      });
    } else {
      reset({
        // appointmentId: selectedAppointmentId,
        name: '',
        phone: '',
        message: '',
        customerRelationRating: '',
        experienceRating: '',
        workQualityRating: '',
        averageRating: '',
      });
    }
  }, [
    reset,
    isUpdate?._id,
    isUpdate?.name,
    isUpdate?.phone,
    isUpdate?.message,
    isUpdate?.customerRelationRating,
    isUpdate?.experienceRating,
    isUpdate?.workQualityRating,
    selectedAppointment,
  ]);

  const handleClear = () => {
    reset();
  };

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: {
        ...formData,
        appoinment: selectedAppointmentId,
        customerRelationRating: Number(customerRelationRating),
        experienceRating: Number(experienceRating),
        workQualityRating: Number(workQualityRating),
        averageRating: Number(handleAverageRating()),
      },
      dispatch,
      handleClose,
      handleClear,
    };

    if (isUpdate?._id) {
      const updateCredentials = {
        ...credentials,
        reviewId: isUpdate?._id,
        handleClose,
      };

      dispatch(updateReviewDetails(updateCredentials));
    } else {
      dispatch(createReview(credentials));
    }
  };

  useEffect(() => {
    const credentials = {
      page: '',
      limit: '',
      search: appoinmentSearch,
      sortBy: '',
      sortDirection: '',
      dispatch,
      sortDate: '',
    };
    dispatch(getAllAppointment(credentials));
  }, [dispatch, appoinmentSearch]);

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        ``{' '}
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography variant="h6">{isUpdate?.name ? 'Review Details' : 'Create Review'}</Typography>
          {isUpdate?._id ? (
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon sx={{ color: 'red' }} />
            </IconButton>
          ) : (
            ''
          )}
        </DialogTitle>
        <DialogContent sx={{ minWidth: '500px' }}>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column">
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {isUpdate?._id ? (
                    ''
                  ) : (
                    <Autocomplete
                      InputProps={{ readOnly: Boolean(isUpdate?._id) }}
                      disablePortal
                      id="combo-box-demo"
                      loading={loading}
                      options={(appointmentList?.list || [])?.map((item) => ({
                        id: item?._id,
                        label: item?.name,
                      }))}
                      sx={{ width: 552 }}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedAppointmentId(newValue?.id);
                          setSelectedAppointment(newValue?.label);
                        } else {
                          setSelectedAppointmentId(null);
                          setSelectedAppointment(null);
                        }
                      }}
                      value={selectedAppointment}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Appointment"
                          onChange={(e) => {
                            setAppoinmentSearch(e.target.value);
                          }}
                          // {...register('appoinment', {
                          //   required: { value: true, message: 'Appointment is required' },
                          // })}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="name"
                    label="Name"
                    {...register('name', {
                      required: { value: true, message: 'Review name is required' },
                    })}
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    sx={{ width: '100%', mb: 2 }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    InputProps={{ readOnly: Boolean(isUpdate?._id) }}
                    type="text"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="phone"
                    label="Phone"
                    {...register('phone', {
                      required: { value: true, message: 'Enquiry phone is required' },
                      maxLength: { value: 15, message: 'Phone number must be 15 characters' },
                      minLength: { value: 9, message: 'Phone number must be 9 characters' },
                    })}
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    sx={{ width: '100%', mb: 2 }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    type="number"
                    InputProps={{ readOnly: Boolean(isUpdate?._id) }}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    name="email"
                    label="Email"
                    {...register('email', {
                      required: { value: true, message: 'Enquiry email is required' },
                    })}
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    sx={{ width: '100%', mb: 2 }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    type="email"
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    InputProps={{ readOnly: Boolean(isUpdate?._id) }}
                    name="message"
                    label="Message"
                    {...register('message', {
                      required: { value: true, message: 'message is required' },
                    })}
                    error={!!errors.message}
                    helperText={errors?.message?.message}
                    type="textarea"
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '100%', mb: 2 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputProps={{ readOnly: Boolean(isUpdate?._id) }}
                    name="customerRelationRating"
                    label="Customer Relation Rating"
                    {...register('customerRelationRating', {
                      required: { value: true, message: 'Customer Realation is required' },
                    })}
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    sx={{ width: '100%', mb: 2 }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    type="number"
                    placeholder="out of 5"
                    onChange={(e) => setCustomerRilationRating(e.target.value)}
                  />
                </Grid>{' '}
                <Grid item xs={6}>
                  <TextField
                    InputProps={{ readOnly: Boolean(isUpdate?._id) }}
                    name="experienceRating"
                    label="Experience Rating"
                    {...register('experienceRating', {
                      required: { value: true, message: 'Experience Rating  is required' },
                    })}
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    sx={{ width: '100%', mb: 2 }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    type="number"
                    placeholder="out of 5"
                    onChange={(e) => setExperienceRating(e.target.value)}
                  />
                </Grid>{' '}
                <Grid item xs={6}>
                  <TextField
                    name="workQualityRating"
                    label="Work Qaulity Rating"
                    {...register('workQualityRating', {
                      required: { value: true, message: 'Work Quality Rating is required' },
                    })}
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    sx={{ width: '100%', mb: 2 }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    type="number"
                    InputProps={{ readOnly: Boolean(isUpdate?._id) }}
                    placeholder="out of 5"
                    onChange={(e) => setWorkQualityRating(e.target.value)}
                  />
                </Grid>{' '}
                <Grid item xs={6}>
                  <TextField
                    InputProps={{ readOnly: Boolean(isUpdate?._id) }}
                    name="avarageRating"
                    label="Avarage Rating"
                    // {...register('avarageRating', {
                    //   required: { value: true, message: 'Avarage Rating is required' },
                    // })}
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    sx={{ width: '100%', mb: 2 }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    type="number"
                    value={
                      experienceRating || customerRelationRating || workQualityRating
                        ? handleAverageRating()?.toFixed(2)
                          ? handleAverageRating()?.toFixed(2)
                          : handleAverageRating()?.toFixed(0)
                        : isUpdate?.averageRating
                    }
                  />
                </Grid>
              </Grid>
              {isUpdate?._id ? (
                ''
              ) : (
                <DialogActions sx={{ mt: 2 }}>
                  <Button autoFocus onClick={handleClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    sx={{
                      backgroundColor: colors.green,
                      color: 'white',
                      padding: '5px 20px',
                    }}
                    size="small"
                    type="submit"
                    variant="contained"
                    color="info"
                    loading={loading}
                    autoFocus
                  >
                    Create
                  </LoadingButton>
                </DialogActions>
              )}
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
